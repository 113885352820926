import styled from 'styled-components';

import QuestionHelper from '@components/common/QuestionHelper';
import { Row } from '@components/common/Layout';
import { colors } from '@theme/colors';


interface DetailsItemProps {
  label: string;
  value: string;
  helperText?: string;
  colorOverride?: string;
};

export const DetailsItem: React.FC<DetailsItemProps> = ({
  label,
  value,
  helperText,
  colorOverride
}) => {
  return (
    <Container>
      <Label
        $colorOverride={colorOverride}
      >
        {label}
      </Label>

      <ValueAndQuestionMarkContainer>
        <Value
          $colorOverride={colorOverride}
        >
          {value}
        </Value>

        {helperText && (
          <QuestionHelper
            text={helperText}
          />
        )}
      </ValueAndQuestionMarkContainer>
    </Container>
  );
};

const Container = styled(Row)`
  border-radius: 12px;
  font-size: 14px;
  justify-content: space-between;
`;

const Label = styled.div<{ $colorOverride?: string }>`
  color: ${props => props.$colorOverride || colors.darkText};
  font-weight: ${props => props.$colorOverride ? '600' : 'normal'};
`;

const ValueAndQuestionMarkContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 8px;
`;

const Value = styled.div<{ $colorOverride?: string }>`
  color: ${props => props.$colorOverride || colors.darkText};
  font-weight: ${props => props.$colorOverride ? '600' : 'normal'};
`;
