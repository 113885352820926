import { useEffect, useState, ReactNode } from 'react';
import { useContractRead } from 'wagmi';

import {
  SellerListingsStore,
  DetailedListing,
  Listing,
  Domain,
  Bid
} from '@helpers/types';
import { esl, ZERO_ADDRESS } from '@helpers/constants';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useAccount from '@hooks/contexts/useAccount';


import SellersContext from './SellersContext';


interface ProvidersProps {
  children: ReactNode;
}

const Sellers = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { isLoggedIn, loggedInEthereumAddress } = useAccount();
  const { swapDomainExchangeAbi, swapDomainExchangeAddress, verifiedDomainRegistryAbi, verifiedDomainRegistryAddress } = useSmartContracts();

  /*
   * State
   */
  const [sellerListingIds, setSellerListingIds] = useState<bigint[] | null>(null);

  const [sellerListings, setSellerListings] = useState<Listing[] | null>(null);
  const [sellerDomains, setSellerDomains] = useState<Domain[] | null>(null);
  const [sellerListingsBids, setSellerListingsBids] = useState<Bid[] | null>(null);

  const [sellerListingsStore, setSellerListingsStore] = useState<SellerListingsStore | null>(null);

  const [shouldFetchSellerDomains, setShouldFetchSellerDomains] = useState<boolean>(false);
  const [shouldFetchSellerListings, setShouldFetchSellerListings] = useState<boolean>(false);
  const [shouldFetchSellerListingsBids, setShouldFetchSellerListingsBids] = useState<boolean>(false);

  /*
   * Contract Reads
   */

  // getUserListings(address _user) (ListingWithId[] memory listingsOutput)
  const {
    data: sellerListingsRaw,
    refetch: refetchSellerListings,
  } = useContractRead({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'getUserListings',
    args: [
      loggedInEthereumAddress
    ],
    enabled: shouldFetchSellerListings
  });

  // getListingBids(uint256[] memory _listingIds) external view returns (BidWithId[][] memory bidInfo)
  const {
    data: sellerBidsRaw,
    refetch: refetchSellerListingsBids,
  } = useContractRead({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'getListingBids',
    args: [
      sellerListingIds
    ],
    enabled: shouldFetchSellerListingsBids
  });

  // getUserDomains(address _user) external view returns (DomainWithId[] memory domainInfo)
  const {
    data: sellerDomainsRaw,
    refetch: refetchSellerDomains,
  } = useContractRead({
    address: verifiedDomainRegistryAddress,
    abi: verifiedDomainRegistryAbi,
    functionName: 'getUserDomains',
    args: [
      loggedInEthereumAddress
    ],
    enabled: shouldFetchSellerDomains
  });

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('shouldFetchListings_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
    esl && console.log('checking loggedInEthereumAddress: ', loggedInEthereumAddress);
    esl && console.log('checking swapDomainExchangeAddress: ', swapDomainExchangeAddress);

    if (isLoggedIn && loggedInEthereumAddress && swapDomainExchangeAddress) {
      esl && console.log('shouldFetchListings_2');

      setShouldFetchSellerListings(true);
    } else {
      esl && console.log('shouldFetchListings_3');

      setShouldFetchSellerListings(false);

      setSellerListings(null);
    }
  }, [isLoggedIn, loggedInEthereumAddress, swapDomainExchangeAddress]);

  useEffect(() => {
    esl && console.log('sellerListings_1');
    esl && console.log('checking sellerListings: ', sellerListingsRaw);
  
    if (sellerListingsRaw && sellerListingsRaw.length > 0) {
      esl && console.log('sellerListings_2');

      const sanitizedListings = sanitizeRawListings(sellerListingsRaw);
      setSellerListings(sanitizedListings);

      const fetchedListingIds = sanitizedListings.map(listing => BigInt(listing.listingId));
      if (fetchedListingIds.length > 0) {
        setSellerListingIds(fetchedListingIds); // Fetches for listings, even if there are no orders

        setShouldFetchSellerListingsBids(true);
      } else {
        setShouldFetchSellerListingsBids(false);

        setSellerListingIds(null);
      }
    } else {
      esl && console.log('sellerListings_3');
  
      setSellerListings(null);
    }
  }, [sellerListingsRaw]);

  useEffect(() => {
    esl && console.log('shouldFetchSellerDomainIds_1');
    esl && console.log('checking isLoggedIn: ', isLoggedIn);
  
    if (isLoggedIn) {
      esl && console.log('shouldFetchSellerDomainIds_2');
  
      setShouldFetchSellerDomains(true);
    } else {
      esl && console.log('shouldFetchSellerDomainIds_3');

      setShouldFetchSellerDomains(false);
    }
  }, [isLoggedIn]);

  useEffect(() => {
    esl && console.log('sellerDomains_1');
    esl && console.log('sellerDomainsRaw: ', sellerDomainsRaw);
    
    if (sellerDomainsRaw && sellerDomainsRaw.length > 0) {
      esl && console.log('sellerDomains_2');

      const sanitizedDomains = sanitizeDomains(sellerDomainsRaw);

      setSellerDomains(sanitizedDomains);
    } else {
      esl && console.log('sellerDomains_3');
  
      setSellerDomains(null);
    }
  }, [sellerDomainsRaw]);

  useEffect(() => {
    esl && console.log('sellerBids_1');
    esl && console.log('checking sellerBidsRaw', sellerBidsRaw);
  
    if (sellerBidsRaw) {
      esl && console.log('sellerBids_2: ', sellerBidsRaw);

      const flattenedBidsWithId = sellerBidsRaw.flat();
      const validBids = flattenedBidsWithId.filter((bidWithId: any) => bidWithId.bid.buyer !== ZERO_ADDRESS);
      if (validBids.length > 0) {
        esl && console.log('sellerBids_3: ', validBids);
  
        const sanitizedBids = sanitizeRawBids(validBids);
        setSellerListingsBids(sanitizedBids);
      } else {
        esl && console.log('sellerBids_4');
    
        setSellerListingsBids([]); // Return empty array to indicate loaded state with no orders
      }
    } else {
      esl && console.log('sellerBids_5');
  
      setSellerListingsBids(null);
    }
  }, [sellerBidsRaw]);
  
  useEffect(() => {
    esl && console.log('sellerListingstore_1');
    esl && console.log('checking sellerListings: ', sellerListings);
    esl && console.log('checking sellerDomains: ', sellerDomains);
    esl && console.log('checking sellerListingsBids: ', sellerListingsBids);
  
    if (sellerListings && sellerDomains && sellerListingsBids) {
      esl && console.log('sellerListingstore_2');

      const bidsByListingIdMap: { [listingId: string]: Bid[] } = {};
      sellerListingsBids.forEach(bid => {
        const listingId = bid.listingId.toString();
        if (!bidsByListingIdMap[listingId]) {
          bidsByListingIdMap[listingId] = [];
        }

        bidsByListingIdMap[listingId].push(bid);
      });

      const detailedSellerListings: DetailedListing[] = sellerListings.map((listing: Listing) => {
        const listingBids = bidsByListingIdMap[listing.listingId.toString()];
        const listingDomain = sellerDomains.find(domain => domain.domainId === listing.domainId);

        return {
          ...listing,
          domain: listingDomain!,   // todo: needs the love of an expert
          bids: listingBids
        };
      });


      const newStore: SellerListingsStore = {
        listings: detailedSellerListings
      };

      setSellerListingsStore(newStore);
    } else {
      esl && console.log('sellerListingstore_3');
  
      setSellerListingsStore(null);
    }
  }, [sellerListings, sellerDomains, sellerListingsBids]);

  /*
   * Helpers
   */

  const sanitizeRawListings = (rawListingsData: any[]) => {
    const sanitizedListings: Listing[] = [];
  
    for (let i = rawListingsData.length - 1; i >= 0; i--) {
      const listingWithIdData = rawListingsData[i];
      const listingData = listingWithIdData.listing;
      
      const listing: Listing = {
        listingId: listingWithIdData.listingId,
        seller: listingData.seller.toString(),
        price: listingData.askPrice,
        createdAt: listingData.createdAt,
        bidIds: listingData.bids,
        domainId: listingData.domainId,
        minBidPrice: listingData.minBidPrice,
        saleEthRecipientAddress: listingData.saleEthRecipient.toString(),
        dkimKeyHash: listingData.dkimKeyHash.toString(),
        isActive: listingData.isActive,
        encryptionKey: listingData.encryptionKey.substring(2),
      };
  
      sanitizedListings.push(listing);
    }
  
    return sanitizedListings;
  };

  const sanitizeRawBids = (rawBidsData: any[]): Bid[] => {
    const sanitizedBids: Bid[] = [];

    for (let i = rawBidsData.length - 1; i >= 0; i--) {
      const bidWithIdData = rawBidsData[i];
      const bidData = bidWithIdData.bid;
      if (!bidData) {
        continue;
      }
      
      const bid: Bid = {
        bidId: bidWithIdData.bidId,
        buyer: bidData.buyer,
        encryptedBuyerId: bidData.encryptedBuyerId,
        buyerIdHash: bidData.buyerIdHash,
        listingId: bidData.listingId,
        price: bidData.price,
        createdAt: bidData.createdAt,
        expiryTimestamp: bidData.expiryTimestamp,
        refundInitiated: bidData.refundInitiated,
        instantAccept: bidWithIdData.buyerInstantAcceptEnabled
      };
  
      sanitizedBids.push(bid);
    }

    return sanitizedBids;
  };

  const sanitizeDomains = (rawDomainsData: any[]): Domain[] => {
    const sanitizedDomains: Domain[] = [];

    for (let i = rawDomainsData.length - 1; i >= 0; i--) {
      const rawDomainWithIdData = rawDomainsData[i];
      const rawDomainData = rawDomainWithIdData.domain;

      const sanitizedDomain: Domain = {
        domainId: rawDomainWithIdData.domainId,
        owner: rawDomainData.owner,
        name: rawDomainData.name,
        expiryTime: rawDomainData.expiryTime
      };

      sanitizedDomains.push(sanitizedDomain);
    }

    return sanitizedDomains;
  };

  return (
    <SellersContext.Provider
      value={{
        sellerListingsStore,
        refetchSellerListingStore: refetchSellerListings,

        sellerListingsBids,
        refetchSellerListingsBids,
        shouldFetchSellerListingsBids,
        
        sellerDomains,
        shouldFetchSellerDomains,
        refetchSellerDomains
      }}
    >
      {children}
    </SellersContext.Provider>
  );
};

export default Sellers;
