import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import Link from '@mui/material/Link';
import { ENSName, AddressDisplayEnum } from 'react-ens-name';

import { Button } from '@components/common/Button';
import { colors } from '@theme/colors';
import { formatEventDateTime, formatEventDateTimeShort } from '@helpers/dateFormat';
import { DetailedListing, Bid } from '@helpers/types';
import { toEthStringWithDecimals } from '@helpers/units';
import { BuyModal } from '@components/Listing/BidModal';
import { Details } from '@components/Listing/Details';
import { decryptMessageWithAccount } from '@helpers/messagEncryption';
import useListings from '@hooks/contexts/useListings';
import useAccount from '@hooks/contexts/useAccount';
import { getLogoForTld } from '@helpers/tldLogo';
import { getGradientForTld } from '@helpers/tldColor';
import { CALLER_ACCOUNT } from '@helpers/constants';
import { BidTable } from '@components/Listing/BidTable';
import { SellerBidTable } from '@components/Listing/Seller/SellerBidTable';
import { FinalizeSaleBidDetails } from '@components/Listing/FinalizeSale';
import { alchemyMainnetEthersProvider } from 'index';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import { Listing } from '@helpers/types/listing';


interface ExpandListingProps {
  listingId: string | undefined;
  handleFinalizeSaleClicked: (details: FinalizeSaleBidDetails) => void;
}

export const ExpandListing: React.FC<ExpandListingProps> = ({
  listingId,
  handleFinalizeSaleClicked
}: ExpandListingProps) => {
  ExpandListing.displayName = "ExpandListing";
  /*
   * Contexts
   */

  const { accountListingHash, createAccountListingHash, isLoggedIn, loggedInEthereumAddress } = useAccount();
  const { activeListingsStore } = useListings();
  const { blockscanUrl } = useSmartContracts();

  /*
   * State
   */

  const [selectedListing, setSelectedListing] = useState<DetailedListing | null>(null);

  const [highestBid, setHighestBid] = useState<Bid | null>(null);
  
  const [shouldShowBuyModal, setShouldShowBuyModal] = useState<boolean>(false);

  /*
   * Hooks
   */

  useEffect(() => {
    if (!accountListingHash && isLoggedIn && createAccountListingHash) {
      const shouldCreateHash = selectedListing && selectedListing.seller === loggedInEthereumAddress;
      console.log('shouldCreateHash: ', shouldCreateHash);
      if (shouldCreateHash) {
        createAccountListingHash();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountListingHash, isLoggedIn, loggedInEthereumAddress]);

  useEffect(() => {
    if (activeListingsStore && activeListingsStore.length > 0 && listingId) {
      const listing = activeListingsStore.find(listing => listing.listingId === BigInt(listingId));

      if (listing) {
        setSelectedListing(listing);
      } else {
        setSelectedListing(null);
      }
    } else {
      setSelectedListing(null);
    }
  }, [activeListingsStore, listingId]);

  useEffect(() => {
    const getHighestBid = (bids: Bid[], listing: Listing): Bid | null => {

      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (!bids || bids.length === 0) return null;
      const highestBid = bids.reduce((max, bid) => {
        if (bid.expiryTimestamp > currentTimestamp && bid.price > max.price) {
          return bid;
        }
        return max;
      });

      if (highestBid.expiryTimestamp <= currentTimestamp) {
        return null;
      }

      return highestBid;
    };

    
    if (selectedListing) {
      const highestBid = getHighestBid(selectedListing.bids, selectedListing);
      if (highestBid) {
        setHighestBid(highestBid);
      } else {
        setHighestBid(null);
      }
    } else {
      setHighestBid(null);
    }
  }, [selectedListing]);
    
  /*
   * Handlers
   */

  const handleBuyButtonClick = () => {
    if (!selectedListing) {
      setShouldShowBuyModal(false);
      return;
    }

    setShouldShowBuyModal(true);
  };

  const handleBuyModalClose = () => {
    setShouldShowBuyModal(false);
  };

  /*
   * Helpers
   */


  const gradientForTld = () => {
    if (selectedListing) {
      return getGradientForTld(selectedListing.domain.name.split('.')[1]);
    } else {
      return getGradientForTld('xyz');
    }
  }

  const logoForTld = () => {
    if (selectedListing) {
      const Logo = getLogoForTld(selectedListing.domain.name.split('.')[1]);

      return <Logo />;
    } else {
      const Logo = getLogoForTld('xyz');

      return <Logo />;
    }
  }

  const domainRegistrationExpiryDate = () => {
    if (selectedListing) {
      return formatEventDateTime(selectedListing.domain.expiryTime);
    } else {
      return 'Loading...';
    }
  }

  const listingCreatedDate = () => {
    if (selectedListing) {
      return formatEventDateTimeShort(selectedListing.createdAt);
    } else {
      return 'Loading...';
    }
  }

  const listingAskPrice = () => {
    if (selectedListing) {
      return toEthStringWithDecimals(selectedListing.price, true, 4);
    } else {
      return 'Loading...';
    }
  };

  const domainOwnedByAddress = () => {
    if (selectedListing) {
      return selectedListing.domain.owner;
    } else {
      return CALLER_ACCOUNT;
    }
  }

  const handleHighestBidFinalizeSaleClicked = async () => {
    if (selectedListing && highestBid && accountListingHash) {

      const decodedBuyerId = await decryptMessageWithAccount(highestBid.encryptedBuyerId, accountListingHash);

      const highestBidDetails: FinalizeSaleBidDetails = {
        bidId: highestBid.bidId.toString(),
        domainName: selectedListing.domain.name,
        decryptedBuyerId: decodedBuyerId
      };

      handleFinalizeSaleClicked(highestBidDetails);
    }
  }

  const ownerEtherscanLink = `${blockscanUrl}/address/${domainOwnedByAddress()}`;

  /*
   * Component
   */

  const renderButton = () => {
    if (isLoggedIn && selectedListing?.seller === loggedInEthereumAddress) {
      if (highestBid) {
        return (
          <Button
            onClick={handleHighestBidFinalizeSaleClicked}
            fullWidth={true}
            bgColor={colors.buttonDefault}
          >
            Finalize Sale
          </Button>
        )
      } else {
        return (
          <Button
            onClick={() => {}}
            fullWidth={true}
            bgColor={colors.buttonDisabled}
          >
            No bids
          </Button>
        )
      }
    } else {
      return (
        <Button
          onClick={handleBuyButtonClick}
          fullWidth={true}
          bgColor={colors.buttonDefault}
        >
          Bid Now
        </Button>
      )
    }
  }

  const renderPriceOrBid = () => {
    if (isLoggedIn && selectedListing?.seller === loggedInEthereumAddress) {
      return (
        <>
          <PriceLabel>
            Highest bid
          </PriceLabel>
          <PriceValue>
            {highestBid ? toEthStringWithDecimals(highestBid.price, true, 4) : '0'} ETH
          </PriceValue>
        </>
      );
    } else {
      return (
        <>
          <PriceLabel>
            Ask price
          </PriceLabel>
          <PriceValue>
            {listingAskPrice()} ETH
          </PriceValue>
        </>
      );
    }
  }

  return (
    <Container>
      {shouldShowBuyModal && selectedListing && (
        <BuyModal
          listing={selectedListing}
          onBackClick={handleBuyModalClose}
        />
      )}
      
      <ImageAndDetailsContainer>
        <LogoAndDomainContainer
          gradient={gradientForTld()}
        >
          <LogoContainer>
            {logoForTld()}
          </LogoContainer>

          <LogoDomainName>
            {selectedListing?.domain.name}
          </LogoDomainName>
        </LogoAndDomainContainer>
        
        <DetailsAndPriceContainer>
          <DetailsContainer>
            <DomainNameAndOwnedByContainer>
              <DomainNameValue>
                {selectedListing?.domain.name}
              </DomainNameValue>

              <OwnedByValue>
                Owned by&nbsp;
                <Link href={ownerEtherscanLink} target="_blank">
                  <ENSName
                    provider={alchemyMainnetEthersProvider}
                    address={domainOwnedByAddress()}
                    displayType={AddressDisplayEnum.FIRST6}
                  />
                </Link>
              </OwnedByValue>
            </DomainNameAndOwnedByContainer>

            <Details
              isLoading={false}
              expiresAtText={domainRegistrationExpiryDate()}
              createdAtText={listingCreatedDate()}
              askPriceText={isLoggedIn && selectedListing?.seller === loggedInEthereumAddress ? `${listingAskPrice()} ETH` : undefined}
            />
          </DetailsContainer>

          <PriceAndButtonContainer>
            <PriceSection>
              {renderPriceOrBid()}
            </PriceSection>

            {renderButton()}

          </PriceAndButtonContainer>
        </DetailsAndPriceContainer>
      </ImageAndDetailsContainer>

      {(isLoggedIn && selectedListing?.seller === loggedInEthereumAddress) ? (
        <SellerBidTable
          listing={selectedListing}
          handleFinalizeBidClicked={handleFinalizeSaleClicked}
        />
      ) : (
        <BidTable
          listing={selectedListing}
        />
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2rem;
  border-radius: 16px;
`;

const ImageAndDetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 2rem;
`;

const LogoAndDomainContainer = styled.div<{ gradient: string }>`
  min-width: 232px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  gap: 2rem;
  
  border-radius: 16px;
  background: ${({ gradient }) => gradient};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`;

const LogoContainer = styled.div`
  color: white;
`;

const LogoDomainName = styled.span`
  color: white;
  font-size: 24px;
`;

const DetailsAndPriceContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1rem;
  padding-top: 1rem;
`;

const DomainNameAndOwnedByContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0 1rem;
`;

const DomainNameValue = styled.span`
  font-size: 28px;
  font-weight: bold;
`;

const OwnedByValue = styled.span`
  font-size: 16px;
`;

const PriceAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.25rem;
  gap: 1rem;

  background-color: ${colors.container};
  border-radius: 16px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
`;

const PriceSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const PriceLabel = styled.span`
  font-size: 14px;
`;

const PriceValue = styled.span`
  font-size: 24px;
  color: ${colors.darkText};
  font-weight: bold;
`;
