import React from 'react';
import styled from 'styled-components/macro'
import { ArrowLeft } from 'react-feather';
import Link from '@mui/material/Link';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { RowBetween } from '@components/layouts/Row';
import { commonStrings } from '@helpers/strings';
import { LoginStatus } from '@helpers/types';
import useAccount from '@hooks/contexts/useAccount';
import { InstantAccept } from './InstantAccept';
import { InstantAcceptInstructions } from '@components/Bids/InstantAccept/Instructions';
import { INSTANT_ACCEPT_DOCS_LINK } from '@helpers/docUrls';

interface InstantAcceptProps {
  handleBackClick: () => void;
};

export const InstantAcceptForm: React.FC<InstantAcceptProps> = ({
  handleBackClick
}) => {
  /*
   * Context
   */

  const { loginStatus } = useAccount();

  /*
   * Component
   */

  return (
    <Container>
      <TitleContainer>
        <RowBetween style={{ padding: '0.25rem 0rem 0.25rem 0rem' }}>
          <div style={{ flex: 0.5 }}>
            <button
              onClick={handleBackClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Instant Accept
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.5 }}/>
        </RowBetween>

        <InstructionContainer>
          {
            <>
              {commonStrings.get('INSTANT_ACCEPT_INSTRUCTIONS')}
              <Link
                href={INSTANT_ACCEPT_DOCS_LINK}
                target='_blank'>
                  Learn More ↗
              </Link>
            </>
          }

          {loginStatus === LoginStatus.EOA && (
            <>
              {commonStrings.get('INSTANT_ACCEPT_ETH_REQUIRED')}

              <Link
                href={'https://bridge.base.org/deposit'}
                target='_blank'>
                  Base Bridge ↗
              </Link>
            </>
          )}
        </InstructionContainer>

        <InstantAcceptInstructions/>
      </TitleContainer>
        
      <VerticalDivider/>

      <InstantAccept 
        onBackClick={handleBackClick}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 660px;
`;

const TitleContainer = styled.div`
  padding: 1.5rem;
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  
  @media (min-width: 600px) {
    max-width: 606px;
    border-radius: 16px;
    border: 1px solid ${colors.defaultBorderColor};
  }
`;

const InstructionContainer = styled.span`
  display: block;
  padding: 1rem 0.5rem;
  color: #333;
  text-align: left;
  line-height: 1.4;
  font-size: 15px;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: #333;
`;

const VerticalDivider = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${colors.defaultBorderColor};
  margin: 0 auto;
`;