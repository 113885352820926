import { createContext } from 'react';

import { BuyerBidsStore } from '@helpers/types';


interface BuyersValues {
  buyerBidsStore: BuyerBidsStore | null;
  refetchBuyerBids: (() => void) | null;
  buyerInstantAcceptEnabled: boolean;
  refetchBuyerInstantAcceptEnabled: (() => void) | null;
};

const defaultValues: BuyersValues = {
  buyerBidsStore: null,
  refetchBuyerBids: null,
  buyerInstantAcceptEnabled: false,
  refetchBuyerInstantAcceptEnabled: null,
};

const BuyersContext = createContext<BuyersValues>(defaultValues);

export default BuyersContext;