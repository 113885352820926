import { ReactNode } from 'react';
import { readContract } from '@wagmi/core';

import { Domain, Abi } from '@helpers/types';
import { esl, CALLER_ACCOUNT } from '@helpers/constants';
import useSmartContracts from '@hooks/contexts/useSmartContracts';

import DomainsContext from './DomainsContext';


interface ProvidersProps {
  children: ReactNode;
}

const Domains = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { verifiedDomainRegistryAddress, verifiedDomainRegistryAbi } = useSmartContracts();

  /*
   * Contract Reads
   */

  const fetchDomainsBatch = async (domainIds: string[]): Promise<Domain[]> => {
    esl && console.log('fetchDomainsBatch:', domainIds);

    try {
      // getDomains(bytes32[] memory _domains) external view returns (DomainWithId[] memory domainInfo)
      const rawDomainsData = await readContract({
        address: verifiedDomainRegistryAddress as `0x${string}`,
        abi: verifiedDomainRegistryAbi as Abi,
        functionName: 'getDomains',
        args: [
          domainIds
        ],
        account: CALLER_ACCOUNT,
      });

      esl && console.log('rawDomainsData:', rawDomainsData);
      const sanitizedDomains =  sanitizeDomains(rawDomainsData as any[]);
      return sanitizedDomains;
    } catch (error) {
      esl && console.error('Error fetching domains batch:', error); 
      
      return [];
    }
  };

  const fetchUserDomains = async (userAddress: string): Promise<Domain[]> => {
    try {
      // getUserDomains(address _user) external view returns (DomainWithId[] memory domainInfo)
      const rawUserDomains = await readContract({
        address: verifiedDomainRegistryAddress as `0x${string}`,
        abi: verifiedDomainRegistryAbi as Abi,
        functionName: 'getUserDomains',
        args: [
          userAddress
        ],
        account: CALLER_ACCOUNT,
      });

      esl && console.error('rawUserDomains:', rawUserDomains);
      
      const sanitizedUserDomains = sanitizeDomains(rawUserDomains as any[]);
      return sanitizedUserDomains;
    } catch (error) {
      esl && console.error('Error fetching user domains:', error);

      return [];
    }
  };

  /*
   * Helpers
   */

  const sanitizeDomains = (rawDomainsData: any[]): Domain[] => {
    const sanitizedDomains: Domain[] = [];

    for (let i = rawDomainsData.length - 1; i >= 0; i--) {
      const rawDomainWithIdData = rawDomainsData[i];
      const rawDomainData = rawDomainWithIdData.domain;

      const sanitizedDomain: Domain = {
        domainId: rawDomainWithIdData.domainId,
        owner: rawDomainData.owner,
        name: rawDomainData.name,
        expiryTime: rawDomainData.expiryTime,
      };

      sanitizedDomains.push(sanitizedDomain);
    }

    return sanitizedDomains;
  };

  return (
    <DomainsContext.Provider
      value={{
        fetchDomainsBatch,
        fetchUserDomains,
      }}
    >
      {children}
    </DomainsContext.Provider>
  );
};

export default Domains;
