export interface CommonStrings {
  // Environment Banner
  LOCAL_ENV_BANNER: string,
  STAGING_TESTNET_ENV_BANNER: string,
  STAGING_ENV_BANNER: string,
  PRODUCTION_ENV_BANNER: string,

  // Registraiton ETH Required
  DOMAIN_VERIFICATION_ETH_REQUIRED: string,

  // Receive Modal
  RECEIVE_FUNDS_INSTRUCTIONS_1: string
  RECEIVE_FUNDS_INSTRUCTIONS_2: string
  RECEIVE_FUNDS_ONRAMP_INSTRUCTIONS: string

  // Extension Instructions
  BROWSER_NOT_SUPPORTED_INSTRUCTIONS: string,
  EXTENSION_DOWNLOAD_INSTRUCTIONS: string,

  // New Listing Form
  NEW_LISTING_INSTRUCTIONS: string,
  NEW_LISTING_PRICE_TOOLTIP: string,
  NEW_LISTING_MIN_BID_PRICE_TOOLTIP: string,
  NEW_LISTING_SALE_ETH_RECIPIENT_TOOLTIP: string,
  NEW_LISTING_EXPIRATION_TOOLTIP: string,

  // Update Listing Form
  UPDATE_LISTING_INSTRUCTIONS: string,
  UPDATE_LISTING_PRICE_TOOLTIP: string,

  // Bid state tooltip
  REFUND_INITIATED_WARNING: string,
  EXPIRED_BID_SELLER_WARNING: string,
  BID_STATE_TOOLTIP: string,

  // Buy Order Modal
  BUY_ORDER_INSTRUCTIONS: string,
  BUY_ORDER_RECIPIENT_USERNAME_TOOLTIP: string,

  // Increase Bid Modal
  INCREASE_BID_INSTRUCTIONS: string,
  INCREASE_BID_PRICE_TOOLTIP: string,

  // Instant Accept
  INSTANT_ACCEPT_INSTRUCTIONS: string,
  INSTANT_ACCEPT_ETH_REQUIRED: string,
  INSTANT_ACCEPT_TOOLTIP: string,

  // Verify Ticket Form
  PROOF_FORM_VERIFY_DOMAIN_INSTRUCTIONS: string,

  // Finalize Sale Form
  PROOF_FORM_FINALIZE_SALE_INSTRUCTIONS: string,
  FINALIZE_SALE_ETH_REQUIRED: string,

  // Proof Table
  NO_VERIFIED_DOMAIN_PROOFS_ERROR: string,
  NO_TRANSFER_PROOFS_ERROR: string,
  NO_VERIFIED_DOMAIN_PROOFS_SUCCESS: string,


  // Mail Input
  INPUT_MODE_TOOLTIP: string,
  EMAIL_TOOLTIP: string,
  PROVING_TYPE_TOOLTIP: string,
  UPLOAD_TYPE_TOOLTIP: string,
  PROOF_TOOLTIP: string,
  PROOF_FORM_UPLOAD_EMAIL_INSTRUCTIONS: string,
  PROOF_FORM_PASTE_EMAIL_INSTRUCTIONS: string,

  // Proof Modal Steps
  PROOF_MODAL_DOWNLOAD_TITLE: string,
  PROOF_MODAL_DOWNLOAD_SUBTITLE: string,
  PROOF_MODAL_UPLOAD_TITLE: string,
  PROOF_MODAL_UPLOAD_SUBTITLE: string,
  PROOF_MODAL_PROVE_TITLE: string,
  PROOF_MODAL_PROVE_REGISTRATION_TITLE: string,
  PROOF_MODAL_PROVE_SUBTITLE_PRIVATE: string,
  PROOF_MODAL_PROVE_SUBTITLE_FAST: string,
  PROOF_MODAL_PROVE_REGISTRATION_SUBTITLE_FAST: string,
  PROOF_MODAL_VERIFY_TITLE: string,
  PROOF_MODAL_VERIFY_SUBTITLE: string,
  PROOF_MODAL_SUBMIT_TITLE: string,
  PROOF_MODAL_REGISTRATION_SUBMIT_TITLE: string,
  PROOF_MODAL_SUBMIT_SUBTITLE: string,
  PROOF_MODAL_REGISTRATION_SUBMIT_SUBTITLE: string,
};

const strings: CommonStrings = {
  // Environment Banner
  LOCAL_ENV_BANNER: `
    You are currently viewing the application on localhost
  `,
  STAGING_TESTNET_ENV_BANNER: `
    You are currently viewing the staging-testnet application
  `,
  STAGING_ENV_BANNER: `
    You are currently viewing the staging application
  `,
  PRODUCTION_ENV_BANNER: `
    Domain Marketplace Alpha Launch —
  `,


  // Registration ETH Required
  DOMAIN_VERIFICATION_ETH_REQUIRED: `
    Base ETH is required to submit a domain verification transaction.
  `,

  // Receive Modal
  RECEIVE_FUNDS_INSTRUCTIONS_1: `
    This address can ONLY receive
  `,
  RECEIVE_FUNDS_INSTRUCTIONS_2: `
    Sending invalid tokens or tokens from other networks will result in lost funds.
  `,
  RECEIVE_FUNDS_ONRAMP_INSTRUCTIONS: `
    Don't have ETH? Try our P2P ramp to get funds if you have an account with one
    of our supported P2P payment platforms.
  `,

  // Extension Instructions
  BROWSER_NOT_SUPPORTED_INSTRUCTIONS: `
    Your browser is not currently supported. Switch to a browser with Sidebar support and
    join our Telegram for updates on supported browsers.
  `,
  EXTENSION_DOWNLOAD_INSTRUCTIONS: `
    ZKP2P's browser sidebar is your browsing assistant that allows
    you to prove ownership of your domains on web2 registries like Namecheap.
  `,

  // New Listing Form
  NEW_LISTING_INSTRUCTIONS: `
    Create a new listing by selecting the domain to include, specifying the asking price in ETH.
  `,
  NEW_LISTING_PRICE_TOOLTIP: `
    Set the price in ETH for your domain listing.
  `,
  NEW_LISTING_MIN_BID_PRICE_TOOLTIP: `
    Set the minimum bid price in ETH for your domain listing.
  `,
  NEW_LISTING_SALE_ETH_RECIPIENT_TOOLTIP: `
    Set the recipient address for the sale of your domain.
  `,
  NEW_LISTING_EXPIRATION_TOOLTIP: `
    Set the number of days your listing will be active.
  `,

  // Instant Accept
  INSTANT_ACCEPT_INSTRUCTIONS: `
    Enable Instant Accept to automatically receive domains when your bid wins. Sellers prefer buyers with Instant Accept, increasing your chances of winning auctions.
  `,
  INSTANT_ACCEPT_ETH_REQUIRED: `
    Base ETH is required to submit an instant accept transaction.
  `,
  INSTANT_ACCEPT_TOOLTIP: `
    Instant accept is a feature that allows the buyer to automatically accept domain transfers to their Namecheap account.
  `,

  // Update Listing Form
  UPDATE_LISTING_INSTRUCTIONS: `
    Update your listing by specifying the new asking price in ETH. Ask price is only indicative.
  `,
  UPDATE_LISTING_PRICE_TOOLTIP: `
    New asking price in ETH.
  `,

  // Expired Bid Seller Warning
  REFUND_INITIATED_WARNING: `
    This bid has initiated a refund. You can still finalize the sale by selecting this bid.
  `,
  EXPIRED_BID_SELLER_WARNING: `
    This bid has expired. The buyer can frontrun you by pulling the funds after you transfer the domain.
  `,
  BID_STATE_TOOLTIP: `
    Bids can have the following states: Active, Refund Initiated, Expired, and Listing Expired
  `,

  // Buy Order Modal
  BUY_ORDER_INSTRUCTIONS: `
    Create a bid by providing your Namecheap username and the amount in ETH.
  `,
  BUY_ORDER_RECIPIENT_USERNAME_TOOLTIP: `
    Your Namecheap username. Make sure it is correct.
  `,

  // Increase Bid Modal
  INCREASE_BID_INSTRUCTIONS: `
    Increase your bid by providing the amount in ETH.
  `,
  INCREASE_BID_PRICE_TOOLTIP: `
    The new bid amount in ETH.
  `,

  // Verify Ticket Form
  PROOF_FORM_VERIFY_DOMAIN_INSTRUCTIONS: `
    Use the browser assistant to prove ownership of domains you own on Namecheap.
  `,

  // Finalize Sale Form
  PROOF_FORM_FINALIZE_SALE_INSTRUCTIONS: `
    Transfer the domain to the correct recipient on Namecheap. Submit transfer email to generate proof and complete sale.
  `,
  FINALIZE_SALE_ETH_REQUIRED: `
    Base ETH is required to submit a finalize sale transaction.
  `,

  // Proof Table
  NO_VERIFIED_DOMAIN_PROOFS_ERROR: `
    No new domain proofs found.
    Please follow instructions in the browser sidebar to generate proof of domains in your Namecheap account.
  `,
  NO_TRANSFER_PROOFS_ERROR: `
    No Namecheap proofs found.
    Please follow instructions in the browser sidebar to generate proof of successful transfer to the recipient.
  `,
  NO_VERIFIED_DOMAIN_PROOFS_SUCCESS: `
    Domain proofs verified successfully.
    Go back and list your domains.
  `,

  // MAIL INPUT
  INPUT_MODE_TOOLTIP: `
    Drag the .eml file into the box to automatically load the contents.
    You can also Paste the contents directly.
  `,
  EMAIL_TOOLTIP: `
    Open any Venmo transaction email and select 'Show original' to view the full contents.
  `,
  PROVING_TYPE_TOOLTIP: `
    Fast verification sends your email to our servers for processing (30 to 60 seconds).
    Private verification processes locally in your browser (10 minutes).
  `,
  UPLOAD_TYPE_TOOLTIP: `
    Login uses Google authentication to pull in your past Venmo transaction emails.
    Data is NEVER stored.
    Upload requires you to manually input the .eml file.
  `,
  PROOF_TOOLTIP: `
    The proof is a cryptographic signature that shows you sent the email using contents
    and signatures that exist in the email.
  `,
  PROOF_FORM_UPLOAD_EMAIL_INSTRUCTIONS: `
    Follow this guide to download and input the email.
  `,
  PROOF_FORM_PASTE_EMAIL_INSTRUCTIONS: `
    Follow this guide to copy and paste the email.
  `,

  // Proof Modal
  PROOF_MODAL_DOWNLOAD_TITLE: `
   Downloading Verification Keys
 `,
  PROOF_MODAL_DOWNLOAD_SUBTITLE: `
   Keys download (1.7GB) will complete in 3 minutes
 `,
  PROOF_MODAL_UPLOAD_TITLE: `
   Uploading Emails
 `,
  PROOF_MODAL_UPLOAD_SUBTITLE: `
   Email is sent to remote server for proving
 `,
  PROOF_MODAL_PROVE_TITLE: `
   Validating Transfer
 `,
  PROOF_MODAL_PROVE_REGISTRATION_TITLE: `
   Validating Email
 `,
  PROOF_MODAL_PROVE_SUBTITLE_PRIVATE: `
   Private validation will take approximately 10 minutes
 `,
  PROOF_MODAL_PROVE_SUBTITLE_FAST: `
   Email validation can take up to 30 seconds
 `,
  PROOF_MODAL_PROVE_REGISTRATION_SUBTITLE_FAST: `
   Email validation can take up to 60 seconds
 `,
  PROOF_MODAL_VERIFY_TITLE: `
   Local Proof Verification
 `,
  PROOF_MODAL_VERIFY_SUBTITLE: `
   Constructing and verifying transaction
 `,
  PROOF_MODAL_SUBMIT_TITLE: `
   Finalize Transfer
 `,
  PROOF_MODAL_REGISTRATION_SUBMIT_TITLE: `
   Complete Registration
 `,
  PROOF_MODAL_SUBMIT_SUBTITLE: `
   Submit transaction to complete the sale
 `,
  PROOF_MODAL_REGISTRATION_SUBMIT_SUBTITLE: `
   Submit transaction to complete registration
 `,
};

export class CommonStringProvider {
  private strings: CommonStrings;

  constructor() {
    this.strings = strings;
  }

  get(key: keyof CommonStrings): string {
    return this.strings[key];
  }
};
