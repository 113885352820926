import React, { useEffect, useState } from 'react';
import { Inbox } from 'react-feather';
import styled, { css } from 'styled-components/macro';

import { RowBetween } from '@components/layouts/Row';
import { ListingRow } from '@components/Listings/ListingRow'; 
import { DetailedListing } from '@helpers/types';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { toEthStringWithDecimals } from '@helpers/units';
import useListings from '@hooks/contexts/useListings';
import useTableScroll from '@hooks/useTableScroll';


export interface ListingRowData {
  listingId: string;
  nameText: string;
  priceText: string;
  numBids: number;
  highestBid: bigint;
};

interface ListingTableProps {
  tldFilter: string | undefined;
};


export const ListingTable: React.FC<ListingTableProps> = ({
  tldFilter
}) => {
  
  const { activeListingsStore } = useListings();

  /*
   * State
   */

  const [listingRowData, setListingRowData] = useState<ListingRowData[]>([]);
  const [isScrollEnabled, setIsScrollEnabled] = useState(false);

  /*
   * Hooks
   */

  const { tableRef, isScrolling } = useTableScroll();
  
  useEffect(() => {
    const tableElement = tableRef.current;
    if (tableElement) {
      setIsScrollEnabled(tableElement.scrollHeight > tableElement.clientHeight);
    }
  }, [listingRowData, tableRef]);
  
  useEffect(() => {
    if (activeListingsStore && activeListingsStore.length > 0) {
      const filteredListings = activeListingsStore.filter((detailedListing: DetailedListing) => {
        const nameText = detailedListing.domain.name;
        console.log(nameText, tldFilter, nameText.endsWith(tldFilter || ''));
        if (tldFilter && !nameText.endsWith(tldFilter)) {
          return false;
        }
        return true;
      });

      const listingRowsData: ListingRowData[] = filteredListings.map((detailedListing: DetailedListing) => {
        const nameText = detailedListing.domain.name;
        
        let numBids = 0;
        let highestBid = BigInt(0);        
        if (detailedListing.bids && detailedListing.bids.length > 0) {
          numBids = detailedListing.bids.length;
          highestBid = detailedListing.bids.reduce((max, bid) => bid.price > max ? bid.price : max, BigInt(0));
        }
        const priceText = `${toEthStringWithDecimals(detailedListing.price, true, 4)} ETH`;

        return {
          listingId: detailedListing.listingId.toString(),
          nameText,
          priceText,
          numBids,
          highestBid,
        };
      });

      // Sort by highest bid
      listingRowsData.sort((a, b) => Number(b.highestBid) - Number(a.highestBid));

      // Sort by number of bids
      listingRowsData.sort((a, b) => b.numBids - a.numBids);

      setListingRowData(listingRowsData);
    } else {
      setListingRowData([]);
    }
  }, [activeListingsStore, tldFilter]);


  /*
   * Component
   */

  return (
    <Container>
      <TitleRow>
        <ThemedText.HeadlineMedium>
          Listings
        </ThemedText.HeadlineMedium>
      </TitleRow>

      <Content>
        {listingRowData.length === 0 ? (
            <ErrorContainer>
              <ThemedText.DeprecatedBody textAlign="center">
                <InboxIcon strokeWidth={1} style={{ marginTop: '2em' }} />
                <div>
                  No listings found
                </div>
              </ThemedText.DeprecatedBody>
            </ErrorContainer>
        ) : (
          <>
            <TableHeaderRow isScrollEnabled={isScrollEnabled}>
              <ColumnHeader>#</ColumnHeader>

              <ColumnHeader>Domain</ColumnHeader>

              <ColumnHeader>Active Bids</ColumnHeader>

              <ColumnHeader>Ask Price</ColumnHeader>

              <ColumnHeader>Highest Bid</ColumnHeader>

              <ColumnHeader>Actions</ColumnHeader>
            </TableHeaderRow>

            <Table ref={tableRef}>
              {listingRowData.map((listingRowData, rowIndex) => (
                <StyledListingRow key={rowIndex}>
                  <ListingRow
                    key={rowIndex}
                    listingId={listingRowData.listingId}
                    domainName={listingRowData.nameText}
                    numBids={listingRowData.numBids}
                    priceText={listingRowData.priceText}
                    highestBid={listingRowData.highestBid}
                    rowIndex={rowIndex}
                    isScrolling={isScrolling}
                  />
                </StyledListingRow>
              ))}
            </Table>
          </>
        )}
      </Content>
    </Container>
  )
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleRow = styled(RowBetween)`
  align-items: flex-end;
  color: ${colors.darkText};
  padding: .5rem 1rem 1.75rem 1rem;
  height: 76px;

  @media (max-width: 600px) {
    flex-wrap: wrap;
    gap: 12px;
    width: 100%;
    padding: 0 1.75rem;
  };
`;

const Content = styled.div`
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  overflow: hidden;
  position: relative;

  @media (min-width: 600px) {
    border-radius: 16px;
  }
`;

const ErrorContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  padding: 36px;
  max-width: 340px;
  min-height: 25vh;
  gap: 36px;
`;

const IconStyle = css`
  width: 48px;
  height: 48px;
  margin-bottom: 0.5rem;
`;

const InboxIcon = styled(Inbox)`
  ${IconStyle}
`;

const TableHeaderRow = styled.div<{ isScrollEnabled: boolean }>`
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 2fr 2fr 2fr;
  padding: 1rem 1.5rem 0.75rem 1.5rem;
  text-align: left;
  color: ${colors.darkText};
  border-bottom: 1px solid ${colors.defaultBorderColor};
  padding-right: ${({ isScrollEnabled }) => isScrollEnabled ? 'calc(1.5rem + 8px)' : '1.5rem'};
`;

const ColumnHeader = styled.div`
  text-align: left;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
  @media (max-width: 600px) {
    font-size: 13px;
  };
`;

const Table = styled.div`
  font-size: 16px;
  color: #616161;
  max-height: 670px;
  overflow-y: auto;
  scrollbar-width: thin;
  
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${colors.defaultBorderColor};
    border-radius: 4px;
  }

  & > * {
    position: relative;
    
    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 1px;
      background-color: ${colors.defaultBorderColor};
    }
  }

  & > *:last-child::after {
    display: none;
  }
`;

const StyledListingRow = styled.div`
  &:last-child {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
  }
`;