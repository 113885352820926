import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ArrowLeft } from 'react-feather';

import { Summary } from '@components/SellerListings/NewListing/Summary';
import { Input } from '@components/Listing/BidModal/Input';
import { Button } from '@components/common/Button';
import { Overlay } from '@components/modals/Overlay';
import {
  DetailedListing,
  UpdateListingTransactionStatus,
  UpdateListingTransactionStatusType,
  LoginStatus
} from '@helpers/types';
import { formatEventDateTime } from '@helpers/dateFormat';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { commonStrings } from '@helpers/strings';
import { Z_INDEX } from '@theme/zIndex';
import { toBigIntEth, toEthStringWithDecimals } from '@helpers/units';
import useAccount from '@hooks/contexts/useAccount';
import useSellers from '@hooks/contexts/useSellers';
import useUpdateListingTransaction from '@hooks/transactions/useUpdateListing';


interface UpdateListingModalProps {
  listing: DetailedListing
  onBackClick: () => void
}

export const UpdateListingModal: React.FC<UpdateListingModalProps> = ({
  listing,
  onBackClick,
}) => {
  /*
   * Contexts
   */

  const { loginStatus, isLoggedIn, loggedInEthereumAddress } = useAccount();
  const { refetchSellerListingStore } = useSellers();

  /*
   * State
   */

  const [updateListingStatus, setUpdateListingStatus] = useState<UpdateListingTransactionStatusType>(UpdateListingTransactionStatus.DEFAULT);

  /*
   * Hooks
   */

  const onUpdateListingSuccessCallback = useCallback((data: any) => {
    console.log('updateListing Succeeded: ', data);

    refetchSellerListingStore?.();
  }, [refetchSellerListingStore]);

  const {
    writeUpdateListingAsync,
    priceInput,
    // saleEthRecipientAddressInput,
    setListingIdInput,
    setPriceInput,
    setSaleEthRecipientAddressInput,
    setShouldConfigureUpdateListingWrite,
    signUpdateListingTransactionStatus,
    mineUpdateListingTransactionStatus,
    // transactionHash
  } = useUpdateListingTransaction(onUpdateListingSuccessCallback);

  useEffect(() => {
    if (!listing) {
      return;
    };

    // Forward the listing id and recipient address as inputs
    setListingIdInput(listing.listingId);

  }, [listing, setListingIdInput]);

  useEffect(() => {
    if (isLoggedIn && loggedInEthereumAddress) {
      setSaleEthRecipientAddressInput(loggedInEthereumAddress);
    }
  }, [isLoggedIn, loggedInEthereumAddress, setSaleEthRecipientAddressInput]);

  useEffect(() => {
    const updateEditListingStatus = async () => {
      const successfulUpdateListingTransaction = mineUpdateListingTransactionStatus === 'success';

      if (successfulUpdateListingTransaction) {
        setUpdateListingStatus(UpdateListingTransactionStatus.TRANSACTION_SUCCEEDED);
      } else {
        if (priceInput) {
          const isNewPriceGreater = toBigIntEth(priceInput) >= listing.minBidPrice;
  
          if (isNewPriceGreater) {
            const signUpdateListingTransaction = signUpdateListingTransactionStatus === 'loading';
            const mineUpdateListingTransaction = mineUpdateListingTransactionStatus === 'loading';
    
            if (signUpdateListingTransaction) {
              setUpdateListingStatus(UpdateListingTransactionStatus.TRANSACTION_SIGNING);
            } else if (mineUpdateListingTransaction) {
              setUpdateListingStatus(UpdateListingTransactionStatus.TRANSACTION_MINING);
            } else {
              setUpdateListingStatus(UpdateListingTransactionStatus.VALID);
            }
          } else {
            setUpdateListingStatus(UpdateListingTransactionStatus.PRICE_NOT_GREATER);
          }
        } else {
          setUpdateListingStatus(UpdateListingTransactionStatus.DEFAULT);
        }
      }
    }
    
    updateEditListingStatus();
  }, [
    priceInput,
    listing,
    signUpdateListingTransactionStatus,
    mineUpdateListingTransactionStatus,
  ]);

  useEffect(() => {
    console.log('updateListingStatus: ', updateListingStatus);

    setShouldConfigureUpdateListingWrite(updateListingStatus === UpdateListingTransactionStatus.VALID);
  }, [updateListingStatus, setShouldConfigureUpdateListingWrite]);

  /*
   * Handlers
   */

  const handleInputChange = (value: string, setInputFunction: React.Dispatch<React.SetStateAction<string>>) => {
    if (value === "") {
      setInputFunction('');
    } else if (value === ".") {
      setInputFunction('0.');
    } else if (isValidInput(value)) {
      setInputFunction(value);
    }
  };

  const handleOverlayClick = () => {
    onBackClick();
  };

  const ctaOnClick = async () => {
    switch (updateListingStatus) {
      case UpdateListingTransactionStatus.VALID:
        try {
          await writeUpdateListingAsync?.();
        } catch (error) {
          console.log('writeUpdateListingAsync failed: ', error);
        }
        break;

      case UpdateListingTransactionStatus.TRANSACTION_SUCCEEDED:
        onBackClick();
        break;

      default:
        break;
    }
  };

  /*
   * Helpers
   */

  function isValidInput(value: string) {
    const isValid = /^-?\d*(\.\d{0,18})?$/.test(value);
    
    return parseFloat(value) >= 0 && isValid;
  }

  const ctaDisabled = (): boolean => {
    switch (updateListingStatus) {
      case UpdateListingTransactionStatus.DEFAULT:
      case UpdateListingTransactionStatus.TRANSACTION_SIGNING:
      case UpdateListingTransactionStatus.TRANSACTION_MINING:
      case UpdateListingTransactionStatus.PRICE_NOT_GREATER:
        return true;

      case UpdateListingTransactionStatus.VALID:
      default:
        return false;
    }
  }

  const ctaLoading = (): boolean => {
    switch (updateListingStatus) {
      case UpdateListingTransactionStatus.TRANSACTION_SIGNING:
      case UpdateListingTransactionStatus.TRANSACTION_MINING:
        return loginStatus === LoginStatus.AUTHENTICATED;

      default:
        return false;
    }
  };

  const ctaText = (): string => {
    switch (updateListingStatus) {      
      case UpdateListingTransactionStatus.TRANSACTION_SIGNING:
        return 'Signing Transaction';

      case UpdateListingTransactionStatus.TRANSACTION_MINING:
        return 'Mining Transaction';

      case UpdateListingTransactionStatus.VALID:
        return 'Update Listing';

      case UpdateListingTransactionStatus.TRANSACTION_SUCCEEDED:
        return 'Go Back';

      case UpdateListingTransactionStatus.PRICE_NOT_GREATER:
        return 'Price must be greater than min bid price';

      case UpdateListingTransactionStatus.DEFAULT:
      default:
        return 'Input valid price';
    }
  };

  const isSummaryLoading = (): boolean => {
    switch (updateListingStatus) {
      case UpdateListingTransactionStatus.VALID:
      case UpdateListingTransactionStatus.TRANSACTION_SIGNING:
      case UpdateListingTransactionStatus.TRANSACTION_MINING:
      case UpdateListingTransactionStatus.PRICE_NOT_GREATER:
        return false;

      default:
        return true;
    }
  }

  /*
   * Component
   */

  return (
    <ModalAndOverlayContainer>
      <Overlay onClick={handleOverlayClick}/>

      <ModalContainer>
        <RowBetween>
          <div style={{ flex: 0.25 }}>
            <button
              onClick={handleOverlayClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Update Listing
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.25 }}/>
        </RowBetween>

        <InstructionsContainer>
          { commonStrings.get('UPDATE_LISTING_INSTRUCTIONS') }
        </InstructionsContainer>

        <BodyContainer>
          <DomainDetailsContainer>
            <DetailsTitle>
              Domain Details
            </DetailsTitle>

            <HorizontalDivider/>

            <DetailsContainer>
              <DetailsValue>Name: {listing.domain.name}</DetailsValue>
              <DetailsValue>Expiry: {formatEventDateTime(listing.domain.expiryTime)}</DetailsValue>
            </DetailsContainer>
          </DomainDetailsContainer>

          <Input
            label="New Ask Price (indicative)"
            name={`priceInput`}
            value={priceInput}
            onChange={(e) => handleInputChange(e.currentTarget.value, setPriceInput)}
            type="number"
            inputLabel="ETH"
            placeholder={toEthStringWithDecimals(listing.price, true, 4)}
            helperText={commonStrings.get('UPDATE_LISTING_PRICE_TOOLTIP')}
          />

          <Summary
            isLoading={isSummaryLoading()}
            domainCount={1}
            pricePerDomain={priceInput}
            listingFee={0.02}   // 2%
          />

          <Button
            disabled={ctaDisabled()}
            loading={ctaLoading()}
            onClick={async () => {
              ctaOnClick();
            }}
            fullWidth={true}
          >
            { ctaText() }
          </Button>
        </BodyContainer>
      </ModalContainer>
    </ModalAndOverlayContainer>
  );
};

const ModalAndOverlayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.overlay};
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${colors.black};
`;

const ModalContainer = styled.div`
  max-height: 80vh;
  width: 80vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.5rem 1.5rem;
  background-color: ${colors.container};
  color: ${colors.black};
  z-index: ${Z_INDEX.buy_modal};
  gap: 1rem;
  overflow-y: auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

const InstructionsContainer = styled.div`
  padding: 0rem 0.75rem;
  color: ${colors.darkText};
  font-size: 15px;
`;

const DomainDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};
  background-color: #FAF9F6;
`;

const HorizontalDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.defaultBorderColor};
  margin: 0.75rem 0 1rem;
`;

const DetailsTitle = styled.div`
  font-size: 14px;
  padding: 0 1rem;
  color: ${colors.darkText};
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  gap: 0.5rem;
`;

const DetailsValue = styled.div`
  font-size: 14px;
  color: #505458;
`;
