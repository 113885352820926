import React from 'react';
import styled from 'styled-components/macro';

import { CustomCheckbox } from '@components/common/Checkbox';


interface ProofRowProps {
  isScrolling: boolean;
  name: string;
  isSelected: boolean;
  onRowClick: () => void;
  rowIndex: number;
}

export const ProofRow: React.FC<ProofRowProps> = ({
  isScrolling,
  name,
  isSelected,
  onRowClick,
  rowIndex
}: ProofRowProps) => {
  ProofRow.displayName = "ProofRow";

  return (
    <Container
      onClick={onRowClick}
      selected={isSelected}
      scrolling={isScrolling}
    >
      <IndexLabel>
        {rowIndex}
      </IndexLabel>

      <DetailsContainer>
        <DetailItem>
          {name}
        </DetailItem>
      </DetailsContainer>
        
      <CheckboxContainer
        scrolling={isScrolling}
      >
        <CustomCheckbox
          checked={isSelected}
          onChange={onRowClick}
          color={'neutral'}
          boxVariant={'outlined'}
        />
      </CheckboxContainer>
    </Container>
  );
};

const Container = styled.div<{ selected: boolean; scrolling: boolean}>`
  display: grid;
  grid-template-columns: 1fr 5fr 1fr;
  padding: 1.5rem 0rem 1.25rem 1.5rem;
  font-size: 14px;
  color: #333;
  align-items: center;

  ${({ selected }) => selected && `
    background-color: #F0F5FA;
    box-shadow: none;
  `}

  ${({ selected, scrolling }) => (!selected && !scrolling) && `
    &:hover {
      background-color: #E5EEF7;
      box-shadow: none;
    }
  `}
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 0.5rem;
`;

const DetailItem = styled.div`
  display: flex;
`;

const IndexLabel = styled.label`
  text-align: left;
`;

const CheckboxContainer = styled.div<{ scrolling: boolean}>`
  display: flex;
  align-items: center;
  justify-content: center;
  grid-column: 3;
`;
