import React, { useEffect } from 'react';
import styled from 'styled-components';

import useBuyers from '@hooks/contexts/useBuyers';
import useMediaQuery from '@hooks/useMediaQuery';
import BidTable from '@components/Bids';


export const Bids: React.FC = () => {
  const currentDeviceSize = useMediaQuery();
  
  /*
   * Contexts
   */

  const { refetchBuyerBids } = useBuyers();

  /*
   * Hooks
   */

  useEffect(() => {
    refetchBuyerBids?.();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageWrapper $isMobile={currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile'}>
      <BidTable />
    </PageWrapper>
  );
};

const PageWrapper = styled.div<{ $isMobile: boolean }>`
  padding-bottom: 7rem;
  
  @media (min-width: 600px) {
    padding: 12px 8px;
    padding-bottom: 3rem;
  }
`;
