import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { Button } from '@components/common/Button';
import { colors } from '@theme/colors';
import { toEthStringWithDecimals } from '@helpers/units';
import { getLogoForTld } from '@helpers/tldLogo';
import { getGradientForTld } from '@helpers/tldColor';
import { AccessoryButton } from '@components/common/AccessoryButton';


interface ListingRowProps {
  nameText: string;
  price: bigint;
  highestBid: bigint;
  numBids: number;
  rowIndex: number;
  onCardClick: () => void;
};

export const ListingCard: React.FC<ListingRowProps> = ({
  nameText,
  price,
  highestBid,
  numBids,
  onCardClick
}: ListingRowProps) => {
  ListingCard.displayName = "ListingCard";

  
  const tld = nameText.split('.')[1];
  const Logo = getLogoForTld(tld);
  const gradient = getGradientForTld(tld);
  
  /*
   * State
   */

  const [isHovered, setIsHovered] = useState(false);

  /*
   * Helpers
   */

  const formattedDisplayPrice = toEthStringWithDecimals(
    highestBid > price ? highestBid : price,
    true,
    4
  );

  /*
   * Component
   */

  return (
    <Container
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onCardClick}
    >
      <LogoAndDomainContainer
        gradient={gradient}
        isHovered={isHovered}
      >
        <LogoContainer>
          <Logo />
        </LogoContainer>

        <DomainName>
          {nameText}
        </DomainName>
      </LogoAndDomainContainer>

      <DetailsAndButtonContainer>
        <ListingDetailsContainer>
          <AskPrice>
            {formattedDisplayPrice} ETH
          </AskPrice>

          <NumBids>
            {numBids} bid{numBids !== 1 ? 's' : ''}
          </NumBids>        
        </ListingDetailsContainer>

        <ButtonContainer>
          <AccessoryButton
            height={44}
            fontSize={14}
            padding={'8px 12px'}
            isHovered={isHovered}
          >
            Bid Now
          </AccessoryButton>
        </ButtonContainer>
      </DetailsAndButtonContainer>
    </Container>
  );
};

const Container = styled.div`
  min-width: 240px;
  display: flex;
  flex-direction: column;
  
  background-color: ${colors.container};
  border-radius: 16px;
  overflow: hidden;
  cursor: pointer;
`;

const LogoAndDomainContainer = styled.div<{ gradient: string; isHovered: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  justify-content: space-between;
  min-height: 80px;

  background: ${({ gradient }) => gradient};
  color: white;

  transition: transform 0.3s ease-in-out;

  ${({ isHovered }) => isHovered && css`
    transform: scale(1.05);
  `}
`;

const LogoContainer = styled.div`
  color: white;
`;

const DomainName = styled.span`
  color: white;
  font-size: 20px;
  word-wrap: break-word;
  overflow-wrap: break-word;
  max-width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 3; // Limit to 3 lines
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const DetailsAndButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
  padding: 1rem 1rem;
`;

const ListingDetailsContainer = styled.div<{isMobile?: boolean}>`
  display: flex;
  flex-direction: column;
  background-color: ${colors.container};
  gap: 5px;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const AskPrice = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${colors.darkText};
`;

const NumBids = styled.span`
  font-size: 14px;
  color: ${colors.grayText};
`;
