import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { ChevronDown } from 'react-feather';

import { SummaryItem } from '@components/SellerListings/NewListing/SummaryItem';
import { colors } from '@theme/colors';


interface SummaryProps {
  isLoading: boolean;
  pricePerDomain: string;
  domainCount: number;
  listingFee: number;
};

export const Summary: React.FC<SummaryProps> = ({
  isLoading,
  pricePerDomain,
  domainCount,
  listingFee,
}) => {
  const ref = useRef<HTMLDivElement>(null);

  /*
   * State
   */

  const [isOpen, setIsOpen] = useState(true);

  /*
   * Hooks
   */

  useEffect(() => {
    if (isLoading) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [isLoading]);

  /*
   * Helpers
   */

  const domainsListedForString = () => {
    if (domainCount === 0 || pricePerDomain === '') {
      return 'Loading...';
    } else if (domainCount === 1) {
      return `${pricePerDomain} ETH`;
    } else {
      return `${pricePerDomain} ETH x ${domainCount}`;
    }
  };
  
  const serviceFeeString = () => {
    if (domainCount === 0 || pricePerDomain === '') {
      return 'Loading...';
    } else if (domainCount === 1) {
      return `${serviceFeePerDomain} ETH`;
    } else {
      return `${serviceFeePerDomain} ETH x ${domainCount}`;
    }
  };

  const payoutPerDomainString = () => {
    if (domainCount === 0 || pricePerDomain === '') {
      return 'Loading...';
    } else if (domainCount === 1) {
      return `${payoutPerDomain} ETH`;
    } else {
      return `${payoutPerDomain} ETH x ${domainCount}`;
    }
  };

  const youMakeString = () => {
    if (domainCount === 0 || pricePerDomain === '') {
      return 'Loading...';
    } else {
      return `${savings} ETH`;
    }
  };

  const helperString = () => {
    return `Approximate extra earnings relative to Namecheap that charges 10%`;
  };

  const serviceFeePerDomain = (listingFee * Number(pricePerDomain)).toFixed(4);
  const payoutPerDomain = (Number(pricePerDomain) - Number(serviceFeePerDomain)).toFixed(4);
  const earnings = Number(payoutPerDomain) * domainCount;
  const savings = (earnings * 0.075).toFixed(4);

  /*
   * Component
   */

  return (
    <Wrapper ref={ref}>
      <TitleLabelAndDropdownIconContainer>
        <TitleLabel>
          {"Listing Summary"}
        </TitleLabel>

        <TitleValueAndChevronContainer>
          <TitleValue>
            
          </TitleValue>  

          <StyledChevronDown
            onClick={() => setIsOpen(!isOpen)}
            $isOpen={isOpen}
          />
        </TitleValueAndChevronContainer>
      </TitleLabelAndDropdownIconContainer>

      <SummaryDropdown $isOpen={isOpen}>
        <HorizontalDivider/>

        <RequirementListContainer>
          <SummaryItem
            label={"Domain listed for"}
            value={domainsListedForString()}
          />

          <SummaryItem 
            label={`Service fees (${listingFee * 100}%)`}
            value={serviceFeeString()}
          />

          <SummaryItem 
            label={"Payout"}
            value={payoutPerDomainString()}
          />

          <SummaryItem 
            label={"Extra earnings with ZKP2P"}
            value={youMakeString()}
            helperText={helperString()}
            colorOverride={colors.orderSummaryGreen}
          />
        </RequirementListContainer>
      </SummaryDropdown>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};
  background: ${colors.container};
`;

const TitleLabelAndDropdownIconContainer = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px 10px;
`;

const TitleLabel = styled.div`
  font-size: 14px;
`;

const TitleValueAndChevronContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  justify-content: space-between;
  gap: 8px;
`;

const TitleValue = styled.div`
  font-size: 15px;
`;

interface StyledChevronDownProps {
  $isOpen?: boolean;
};

const StyledChevronDown = styled(ChevronDown)<StyledChevronDownProps>`
  width: 20px;
  height: 20px;
  color: ${colors.chevronDown};

  transition: transform 0.4s;
  transform: ${({ $isOpen }) => $isOpen ? 'rotate(180deg)' : 'rotate(0deg)'};
`;

const HorizontalDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.defaultBorderColor};
`;

interface SummaryDropdownProps {
  $isOpen?: boolean;
};

const SummaryDropdown = styled.div<SummaryDropdownProps>`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: ${colors.container};
  color: ${colors.darkText};
  align-items: center;
  gap: 16px;
  overflow: hidden;

  max-height: ${({ $isOpen }) => $isOpen ? '500px' : '0px'};
  transition: max-height 0.4s ease-out;
`;

const RequirementListContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 16px;
`;
