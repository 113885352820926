import React, { useEffect } from 'react';
import styled from 'styled-components';

import Listings from '@components/SellerListings';
import useMediaQuery from '@hooks/useMediaQuery';
import useAccount from '@hooks/contexts/useAccount';
import useSellers from '@hooks/contexts/useSellers';


export const SellerListingsPage: React.FC = () => {
  /*
   * Contexts
   */

  const currentDeviceSize = useMediaQuery();
  const { isLoggedIn } = useAccount();
  const { shouldFetchSellerDomains, refetchSellerDomains, shouldFetchSellerListingsBids, refetchSellerListingsBids } = useSellers();

  /*
   * Hooks
   */

  useEffect(() => {
    if (!isLoggedIn) {
      return;
    }

    // Refetch all of the seller's domains, no dependencies
    if (shouldFetchSellerDomains) {
      refetchSellerDomains?.();
    };

    if (shouldFetchSellerListingsBids) {
      refetchSellerListingsBids?.();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  /*
   * Component
   */

  return (
    <PageWrapper $isMobile={currentDeviceSize === 'tablet' || currentDeviceSize === 'mobile'}>
      <Listings />
    </PageWrapper>
  );
};

const PageWrapper = styled.div<{ $isMobile: boolean }>`
  padding-bottom: 7rem;
  
  @media (min-width: 600px) {
    padding: 12px 8px;
    padding-bottom: 3rem;
  }
`;
  