import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';

import useListings from '@hooks/contexts/useListings';
import { AutoColumn } from '@components/layouts/Column';
import { ListingCarousel } from '@components/Browse/ListingCarousel';
import { DetailedListing } from '@helpers/types';

export interface ListingTableData {
  title: string;
  subTitle: string;
  listings: DetailedListing[];
}

const MIN_LISTINGS_REQUIRED_FOR_TLD = 0;

export default function Browse() {
  const navigate = useNavigate();

  /*
   * Contexts
   */

  const { activeListingsStore } = useListings();

  /*
   * State
   */

  const [popularListings, setPopularListings] = useState<DetailedListing[]>([]);
  const [highestBidListings, setHighestBidListings] = useState<DetailedListing[]>([]);
  const [tldListings, setTldListings] = useState<{ [key: string]: DetailedListing[] }>({});

  /*
   * Hooks
   */

  const orderListingsByHighestBid = (listings: DetailedListing[]): DetailedListing[] => {
    return listings.sort((a, b) => {
      const aHighestBid = a.bids?.reduce((max, bid) => (bid.price && bid.price > max) ? bid.price : max, BigInt(0)) || BigInt(0);
      const bHighestBid = b.bids?.reduce((max, bid) => (bid.price && bid.price > max) ? bid.price : max, BigInt(0)) || BigInt(0);
      return Number(bHighestBid) - Number(aHighestBid);
    });
  };

  const orderListingsByHighestNumBids = (listings: DetailedListing[]): DetailedListing[] => {
    return listings.sort((a, b) => (b.bids?.length || 0) - (a.bids?.length || 0));
  };
    
  useEffect(() => {
    if (activeListingsStore && activeListingsStore.length > 0) {
      const popularListings = orderListingsByHighestNumBids(activeListingsStore);
      setPopularListings(popularListings);

      const highestBidListings = orderListingsByHighestBid(activeListingsStore);
      setHighestBidListings(highestBidListings);

      const tldGroups: { [key: string]: DetailedListing[] } = {};
      activeListingsStore.forEach(listing => {
        const tld = listing.domain.name.split('.').pop();
        if (tld) {
          if (!tldGroups[tld]) {
            tldGroups[tld] = [];
          }
          tldGroups[tld].push(listing);
        }
      });

      const sortedTldListings: { [key: string]: DetailedListing[] } = {};
      Object.entries(tldGroups)
        .sort(([, a], [, b]) => b.length - a.length)
        .forEach(([tld, listings]) => {
          sortedTldListings[tld] = orderListingsByHighestBid(listings);
        });

      setTldListings(sortedTldListings);
    } else {
      setPopularListings([]);
      setHighestBidListings([]);
      setTldListings({});
    }
  }, [activeListingsStore]);

  /*
   * Handlers
   */

  const handleSeeAllPopularListingsClick = () => {
    navigate('/listings');
  };

  const handleSeeAllHighestBidListingsClick = () => {
    navigate('/listings');
  };

  const handleSeeAllTldListingsClick = (tld: string) => {
    navigate(`/listings/${tld}`);
  };
  
  /*
   * Component
   */

  return (
    <Wrapper>
      {popularListings.length > MIN_LISTINGS_REQUIRED_FOR_TLD && (
        <ListingCarousel
          key="popular"
          tableTitle="Popular Domains"
          subTitle="Domains with the most bids"
          listings={popularListings}
          handleSeeAllButtonClick={handleSeeAllPopularListingsClick}
        />
      )}

      {highestBidListings.length > MIN_LISTINGS_REQUIRED_FOR_TLD && (
        <ListingCarousel
          key="top"
          tableTitle="Top Domains"
          subTitle="Domains with the highest bids"
          listings={highestBidListings}
          handleSeeAllButtonClick={handleSeeAllHighestBidListingsClick}
        />
      )}

      {Object.entries(tldListings).map(([tld, listings]) => 
        listings.length > MIN_LISTINGS_REQUIRED_FOR_TLD && (
          <ListingCarousel
            key={tld}
            tableTitle={tld.toUpperCase()}
            subTitle={`Top ${listings.length} .${tld} domains`}
            listings={listings}
            handleSeeAllButtonClick={() => handleSeeAllTldListingsClick(tld)}
          />
        )
      )}
    </Wrapper>
  );
};

const Wrapper = styled(AutoColumn)`
  width: 1032px;
  margin: auto;
  gap: 2rem;
`;
