import { createContext } from 'react';

import { DetailedListing } from '@helpers/types';


interface ListingsValues {
  activeListingsStore: DetailedListing[] | null;
  refetchListingCounter: (() => void) | null;
  refetchActiveListings: (() => void) | null;
  refetchActiveListingBids: (() => void) | null;
  shouldFetchActiveListings: boolean;
  shouldFetchListingCounter: boolean;
};

const defaultValues: ListingsValues = {
  activeListingsStore: null,
  refetchListingCounter: null,
  refetchActiveListings: null,
  refetchActiveListingBids: null,
  shouldFetchActiveListings: false,
  shouldFetchListingCounter: false,
};

const ListingsContext = createContext<ListingsValues>(defaultValues);

export default ListingsContext;
