type Contracts = {
  [network: string]: {
    [contract: string]: string;
  };
};

export const contractAddresses: Contracts = {
  'base_production': {
    'swapDomainExchange': '',
    'verifiedDomainRegistry': '',
    'nullifierRegistry': '',
    'ticketNft': '',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE'
  },
  'base_staging': {
    'swapDomainExchange': '0xF2A66ec90C07f51cc29F67f99A43aCc92255d7dd',
    'verifiedDomainRegistry': '0x17463cb89A62c7b4A5ecD949aFDEDBD0Aa047ad1',
    'nullifierRegistry': '0x6992997BEe56d0Bd552397885eED681fD704D1ed',
    'ticketNft': '',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE'
  },
  'sepolia_staging': {
    'swapDomainExchange': '0xe2B378D9181046c84dB1156B0F90cF3108e25E9D',
    'verifiedDomainRegistry': '0xf491470B2459d65C92D0E6673A7672E2FFB26F02',
    'nullifierRegistry': '0x5eDB64B6FF3Fe18CE0bf7a890a6cC6a7D236c925',
    'ticketNft': '',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE',
  },
  'localhardhat': {
    'swapDomainExchange': '0xe7f1725E7734CE288F8367e1Bb143E90bb3F0512',
    'verifiedDomainRegistry': '0x0165878A594ca255338adfa4d48449f69242Eb8F',
    'nullifierRegistry': '0xCf7Ed3AccA5a467e9e704C703E8D87F634fB0Fc9',
    'ticketNft': '',
    'socketBridge': '0x3a23f943181408eac424116af7b7790c94cb97a5',
    'lifiBridge': '0x1231DEB6f5749EF6cE6943a275A1D3E7486F4EaE',
  },
};

export const blockExplorerUrls: { [network: string]: string } = {
  'hardhat': 'https://etherscan.io',
  'sepolia': 'https://sepolia.etherscan.io/',
  'base': 'https://basescan.org'
};
