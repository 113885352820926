export interface TldInfo {
  name: string;
  gradient: string;
}

export const tldGradients: TldInfo[] = [
  { name: 'com', gradient: 'linear-gradient(135deg, #6A5ACD, #4B0082)' },
  { name: 'org', gradient: 'linear-gradient(135deg, #483D8B, #4169E1)' },
  { name: 'xyz', gradient: 'linear-gradient(135deg, #8A2BE2, #9370DB)' },
  { name: 'io', gradient: 'linear-gradient(135deg, #0000FF, #1E90FF)' },
  { name: 'ai', gradient: 'linear-gradient(135deg, #4B0082, #8A2BE2)' },
  { name: 'app', gradient: 'linear-gradient(135deg, #00008B, #4169E1)' },
  { name: 'dev', gradient: 'linear-gradient(135deg, #191970, #4682B4)' },
  { name: 'info', gradient: 'linear-gradient(135deg, #0000CD, #6495ED)' },
  { name: 'edu', gradient: 'linear-gradient(135deg, #483D8B, #7B68EE)' },
  { name: 'gov', gradient: 'linear-gradient(135deg, #000080, #4169E1)' },
  { name: 'co', gradient: 'linear-gradient(135deg, #8A2BE2, #9932CC)' },
  { name: 'biz', gradient: 'linear-gradient(135deg, #4B0082, #9370DB)' },
  { name: 'me', gradient: 'linear-gradient(135deg, #191970, #6A5ACD)' },
  { name: 'tv', gradient: 'linear-gradient(135deg, #00008B, #1E90FF)' },
  { name: 'fm', gradient: 'linear-gradient(135deg, #4169E1, #87CEFA)' },
  { name: 'tech', gradient: 'linear-gradient(135deg, #0000FF, #4682B4)' },
  { name: 'store', gradient: 'linear-gradient(135deg, #483D8B, #9370DB)' },
  { name: 'blog', gradient: 'linear-gradient(135deg, #4B0082, #8A2BE2)' },
  { name: 'photo', gradient: 'linear-gradient(135deg, #6A5ACD, #9932CC)' },
  { name: 'cafe', gradient: 'linear-gradient(135deg, #191970, #4169E1)' },
  { name: 'net', gradient: 'linear-gradient(135deg, #000080, #6495ED)' },
];

export const getGradientForTld = (tld: string): string => {
  const tldInfo = tldGradients.find(t => t.name === tld.toLowerCase());

  return tldInfo ? tldInfo.gradient : 'linear-gradient(135deg, #6e8efb, #a777e3)';
};
