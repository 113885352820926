import React from 'react';
import styled, { css } from 'styled-components';
import { Zap, ZapOff } from 'react-feather';
import { ENSName, AddressDisplayEnum } from 'react-ens-name';
import Link from '@mui/material/Link';

import useMediaQuery from '@hooks/useMediaQuery';
import { colors } from '@theme/colors';
import { BidState } from '@helpers/types/bid';
import QuestionHelper from '@components/common/QuestionHelper';
import { commonStrings } from '@helpers/strings';
import { formatEventDateTimeShort } from '@helpers/dateFormat';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import { alchemyMainnetEthersProvider } from 'index';
import {
  getBidStateColor,
  getBidStateString,
  getInstantAcceptColor,
  getInstantAcceptString
} from '@helpers/bidTableHelpers';

interface BidRowProps {
  listingId: string;
  buyerAddress: string;
  bidPrice: string;
  rowIndex: number;
  createdAt: bigint;
  bidState: BidState;
  instantAccept: boolean;
  isScrolling: boolean;
}

export const BidRow: React.FC<BidRowProps> = ({
  listingId,
  buyerAddress,
  bidPrice,
  rowIndex,
  createdAt,
  bidState,
  instantAccept,
  isScrolling
}) => {
  BidRow.displayName = "BidRow";

  const isMobile = useMediaQuery() === 'mobile';

  const { blockscanUrl } = useSmartContracts();

  const buyerEtherscanLink = `${blockscanUrl}/address/${buyerAddress}`;

  return (
    <Container isScrolling={isScrolling}>
      <BidDetailsContainer isMobile={isMobile}>
        <SummaryLabelValue>
          {rowIndex + 1}
        </SummaryLabelValue>

        <SummaryLabelValue style={{ color: getBidStateColor(bidState) }}>
          {bidState === BidState.Active ? (
            getBidStateString(bidState)
          ) : bidState === BidState.RefundInitiated ? (
            <>
              {getBidStateString(bidState) + ' '}
              <QuestionHelper 
                text={commonStrings.get('REFUND_INITIATED_WARNING')}
              />
            </>
          ) : (
            <>
              {getBidStateString(bidState) + ' '}
              <QuestionHelper 
                text={commonStrings.get('EXPIRED_BID_SELLER_WARNING')}
              />
            </>
          )}
        </SummaryLabelValue>

        <PriceLabel>
          {bidPrice}
        </PriceLabel>

        <SummaryLabelValue>
          <Link href={buyerEtherscanLink} target="_blank">
            <ENSName
              provider={alchemyMainnetEthersProvider}
              address={buyerAddress}
              displayType={AddressDisplayEnum.FIRST6}
            />
          </Link>
        </SummaryLabelValue>

        <InstantAcceptContainer>
          <SummaryLabelValue style={{ color: getInstantAcceptColor(instantAccept) }}>
            {getInstantAcceptString(instantAccept)}
          </SummaryLabelValue>
          {instantAccept ? (
            <StyledZap size={16} />
          ) : (
            <StyledZapOff size={16} />
          )}
        </InstantAcceptContainer>

        <SummaryLabelValue>{formatEventDateTimeShort(createdAt)}</SummaryLabelValue>
      </BidDetailsContainer>
    </Container>
  );
};

const Container = styled.div<{isScrolling: boolean}>`
  display: flex;

  ${({ isScrolling }) => isScrolling && css`
    padding-right: 0.5rem;
    transition: padding-right 0.3s ease;
  `}

  ${({ isScrolling }) => !isScrolling && css`
    padding-right: 0rem;
    transition: padding-right 0.3s ease;
  `}
`;

const BidDetailsContainer = styled.div<{isMobile?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 2fr 2fr 3fr 3fr 3fr;
  align-items: center;
  padding: 1rem 1.5rem;
  line-height: 24px;
`;


const SummaryLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

const PriceLabel = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
`;

const InstantAcceptContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

const StyledZap = styled(Zap)`
  color: ${colors.accessoryButtonIconDefault};
`;

const StyledZapOff = styled(ZapOff)`
  color: ${colors.accessoryButtonIconDefault};
`;