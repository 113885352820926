import React, { useState, useEffect } from 'react';
import styled from 'styled-components/macro';

import useAccount from '@hooks/contexts/useAccount';
import useBuyers from '@hooks/contexts/useBuyers';
import { AutoColumn } from '@components/layouts/Column';
import { BidTable } from '@components/Bids/BidTable';
import { InstantAcceptForm } from '@components/Bids/InstantAccept';
import { BidState } from '@helpers/types/bid';


export default function Bids() {

  const { isLoggedIn } = useAccount();
  const { buyerBidsStore } = useBuyers();

  /*
   * State
   */

  const [showExpiredBids, setShowExpiredBids] = useState(false);

  const [showInstantAccept, setShowInstantAccept] = useState(false);

  /*
   * Hooks
   */

  useEffect(() => {
    if (isLoggedIn && buyerBidsStore) {
      const buyerBids = buyerBidsStore.buyerBids;

      if (buyerBids.length > 0) {
        setShowExpiredBids(true);
      }
    }
  }, [isLoggedIn, buyerBidsStore]);

  /*
   * Handlers
   */

  const handleInstantAcceptPressed = () => {
    setShowInstantAccept(true);
  }

  const handleInstantAcceptBackPressed = () => {
    setShowInstantAccept(false);
  }

  /*
   * Component
   */

  const renderContent = () => {
    if (showInstantAccept) {
      return (
        <InstantAcceptForm
          handleBackClick={handleInstantAcceptBackPressed}
        />
      );
    } else {
      return (
        <>
          <BidTable 
            tableTitle="Active Bids"
            emptyTableMessage="You have no active bids"
            statusFilters={[BidState.Active, BidState.RefundInitiated]}
            showTopRightButton={true}
            handleTopRightButtonPressed={handleInstantAcceptPressed}
          />
      
          {showExpiredBids && (
            <ExpiredBidsTableContainer>
              <BidTable 
                tableTitle="Expired Bids"
                emptyTableMessage="You have no expired bids"
                statusFilters={[BidState.Expired, BidState.ListingExpired]}
                showTopRightButton={false}
                handleTopRightButtonPressed={() => {}}
              />
            </ExpiredBidsTableContainer>
          )}
        </>
      );
    }
  }

  return (
    <Wrapper>
      {renderContent()}
    </Wrapper>
  );
};

const Wrapper = styled(AutoColumn)`
  width: 920px;
  margin: auto;
`;


const ExpiredBidsTableContainer = styled.div`
  margin-top: 1rem;
`;
