import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { useParams } from 'react-router-dom';

import { AutoColumn } from '@components/layouts/Column';
import { STATE_REFETCH_INTERVAL } from '@helpers/constants';
import useListings from '@hooks/contexts/useListings';
import { ListingTable } from './ListingTable';


export default function Listings() {
  /*
   * Contexts
   */

  const {
    shouldFetchActiveListings,
    refetchActiveListings,
    shouldFetchListingCounter,
    refetchListingCounter
  } = useListings();

  /*
   * Hooks
   */

  const { tldFilter } = useParams<{ tldFilter?: string }>();

  useEffect(() => {
    if (shouldFetchListingCounter) {
      const intervalId = setInterval(() => {
        refetchListingCounter?.();
      }, STATE_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchListingCounter]);

  useEffect(() => {
    if (shouldFetchActiveListings) {
      const intervalId = setInterval(() => {
        refetchActiveListings?.();
      }, STATE_REFETCH_INTERVAL);

      return () => clearInterval(intervalId);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldFetchActiveListings]);

  /*
   * Component
   */

  return (
    <Wrapper>
      <ListingTable 
        tldFilter={tldFilter}
      />
    </Wrapper>
  );
};

const Wrapper = styled(AutoColumn)`
  width: 920px;
  margin: auto;
`;
