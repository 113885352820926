import { createContext } from 'react';

import { DetailedDomain, Bid, SellerListingsStore } from '@helpers/types';


interface SellersValues {
  sellerListingsStore: SellerListingsStore | null;
  refetchSellerListingStore: (() => void) | null;

  sellerListingsBids: Bid[] | null;
  refetchSellerListingsBids: (() => void) | null;
  shouldFetchSellerListingsBids: boolean;

  sellerDomains: DetailedDomain[] | null;
  refetchSellerDomains: (() => void) | null;
  shouldFetchSellerDomains: boolean;
};

const defaultValues: SellersValues = {
  sellerListingsStore: null,
  refetchSellerListingStore: null,

  sellerListingsBids: null,
  refetchSellerListingsBids: null,
  shouldFetchSellerListingsBids: false,

  sellerDomains: null,
  shouldFetchSellerDomains: false,
  refetchSellerDomains: null,
};

const SellersContext = createContext<SellersValues>(defaultValues);

export default SellersContext;
