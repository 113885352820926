import { useEffect, useState, ReactNode, useRef } from 'react';
import { useContractRead } from 'wagmi';
import { readContract } from '@wagmi/core';

import { Listing, DetailedListing, Bid, Domain, Abi } from '@helpers/types';
import { esl, CALLER_ACCOUNT, ZERO } from '@helpers/constants';
import useSmartContracts from '@hooks/contexts/useSmartContracts';
import useDomains from '@hooks/contexts/useDomains';

import ListingsContext from './ListingsContext';


const BATCH_SIZE = 30;
const PRUNED_LISTINGS_PREFIX = 'prunedListings_';

interface ProvidersProps {
  children: ReactNode;
}

const Listings = ({ children }: ProvidersProps) => {
  /*
   * Contexts
   */

  const { swapDomainExchangeAddress, swapDomainExchangeAbi } = useSmartContracts();
  const { fetchDomainsBatch } = useDomains();

  /*
   * State
   */

  const currentRampAddressRef = useRef(swapDomainExchangeAddress);

  const [fetchListingsTrigger, setFetchListingsTrigger] = useState(0);

  const [listingCounter, setListingCounter] = useState<bigint | null>(null);

  const [activeListingIds, setActiveListingIds] = useState<bigint[] | null>(null);
  const [activeListingDomainIds, setActiveListingDomainIds] = useState<string[] | null>(null);

  const [activeListings, setActiveListings] = useState<Listing[] | null>(null);
  const [activeListingBids, setActiveListingBids] = useState<Bid[] | null>(null);
  const [activeListingDomains, setActiveListingDomains] = useState<Domain[] | null>(null);

  const [activeListingsStore, setActiveListingsStore] = useState<DetailedListing[] | null>(null);

  const [shouldFetchListingCounter, setShouldFetchListingCounter] = useState<boolean>(false);

  const [shouldFetchActiveListings, setShouldFetchActiveListings] = useState<boolean>(false);
  const [shouldFetchActiveListingDomains, setShouldFetchActiveListingDomains] = useState<boolean>(false);
  const [shouldFetchActiveListingBids, setShouldFetchActiveListingBids] = useState<boolean>(false);

  /*
   * Contract Reads
   */

  // uint256 public listingCounter;
  const {
    data: listingCounterRaw,
    refetch: refetchListingCounter,
  } = useContractRead({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'listingCounter',
    enabled: shouldFetchListingCounter,
    account: CALLER_ACCOUNT
  })

  // getListingBids(uint256[] memory _listingIds) (BidWithId[][] memory bidsOutput) 
  const {
    data: activeListingBidsRaw,
    refetch: refetchActiveListingBids,
  } = useContractRead({
    address: swapDomainExchangeAddress,
    abi: swapDomainExchangeAbi,
    functionName: 'getListingBids',
    args: [
      activeListingIds
    ],
    enabled: shouldFetchActiveListingBids
  });

  /*
   * Hooks
   */

  useEffect(() => {
    esl && console.log('shouldFetchListingCounter_1');
    esl && console.log('checking swapDomainExchangeAddress: ', swapDomainExchangeAddress);

    if (swapDomainExchangeAddress) {
      esl && console.log('shouldFetchListingCounter_2');

      setShouldFetchListingCounter(true);
    } else {
      esl && console.log('shouldFetchListingCounter_3');

      setShouldFetchListingCounter(false);

      setActiveListings(null);
    }
  }, [swapDomainExchangeAddress]);

  useEffect(() => {
    esl && console.log('listingCounter_1');
    esl && console.log('checking listingCounterRaw: ', listingCounterRaw);
  
    if (listingCounterRaw || listingCounterRaw === ZERO) { // BigInt(0) is falsy)
      esl && console.log('listingCounter_2');
      
      setListingCounter(listingCounterRaw as bigint);
    } else {
      esl && console.log('listingCounter_3');
      
      setListingCounter(null);
    }
  }, [listingCounterRaw]);
  
  useEffect(() => {
    currentRampAddressRef.current = swapDomainExchangeAddress;
  }, [swapDomainExchangeAddress]);
  
  useEffect(() => {
    esl && console.log('shouldFetchActiveListings_1');
    esl && console.log('checking listingCounter: ', listingCounter);
    esl && console.log('checking swapDomainExchangeAddress: ', swapDomainExchangeAddress);
  
    const fetchData = async () => {
      if (listingCounter && swapDomainExchangeAddress) {
        esl && console.log('shouldFetchActiveListings_2');
  
        setShouldFetchActiveListings(true);
  
        await fetchAndPruneListings(listingCounter, swapDomainExchangeAddress);
      } else {
        esl && console.log('shouldFetchActiveListings_3');
  
        setShouldFetchActiveListings(false);
  
        setActiveListings(null);
        setActiveListingsStore(null);
      }
    };
  
    fetchData();
  
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listingCounter, swapDomainExchangeAddress, fetchListingsTrigger]);

  useEffect(() => {
    esl && console.log('activeListingOrders_1');
    esl && console.log('checking activeListingBidsRaw', activeListingBidsRaw);
  
    if (activeListingBidsRaw) {
      esl && console.log('activeListingBids_2');
      const flattenedListingBids = activeListingBidsRaw.flat();

      if (flattenedListingBids.length > 0) {
        esl && console.log('activeListingBids_3');
  
        const sanitizedBids = sanitizeRawBids(flattenedListingBids);
        
        setActiveListingBids(sanitizedBids);
      } else {
        esl && console.log('activeListingBids_4');
    
        setActiveListingBids([]); // Return empty array to indicate loaded state with no bids
      }
    } else {
      esl && console.log('activeListingBids_5');
  
      setActiveListingBids(null);
    }
  }, [activeListingBidsRaw]);

  useEffect(() => {
    esl && console.log('activeListingDomains_1');
    esl && console.log('checking activeListingDomainIds: ', activeListingDomainIds);

    if (fetchDomainsBatch && activeListingDomainIds && activeListingDomainIds.length > 0) {
      esl && console.log('activeListingDomains_2');

      const fetchDomains = async () => {
        try {
          const domains = await fetchDomainsBatch(activeListingDomainIds);
          
          esl && console.log('domains: ', domains);

          setActiveListingDomains(domains);
        } catch (error) {
          esl && console.log('activeListingDomains_3');
          
          setActiveListingDomains(null);
        }
      };
  
      fetchDomains();
    } else {
      esl && console.log('activeListingDomains_4');

      setActiveListingDomains(null);
    }

  }, [activeListingDomainIds, shouldFetchActiveListingDomains, fetchDomainsBatch]);
  
  useEffect(() => {
    esl && console.log('activeListingstore_1');
    esl && console.log('checking activeListings: ', activeListings);
    esl && console.log('checking activeListingDomains: ', activeListingDomains); 
    esl && console.log('checking activeListingBids: ', activeListingBids);
  
    if (activeListings && activeListingDomains && activeListingBids && activeListingDomains.length > 0) {
      esl && console.log('activeListingstore_2');

      const bidsByListingIdMap: { [listingId: string]: Bid[] } = {};
      activeListingBids.forEach(bid => {
        const listingId = bid.listingId.toString();
        if (!bidsByListingIdMap[listingId]) {
          bidsByListingIdMap[listingId] = [];
        }

        bidsByListingIdMap[listingId].push(bid);
      });

      const detailedActiveListings: DetailedListing[] = activeListings
        .filter((listing: Listing) => {
          const domain = activeListingDomains.find(domain => domain.domainId === listing.domainId);
          return domain !== undefined;
        })
        .map((listing: Listing) => {
          const bids = bidsByListingIdMap[listing.listingId.toString()] || [];
          const domain = activeListingDomains.find(domain => domain.domainId === listing.domainId)!;
          
          return {
            ...listing,
            domain,
            bids
          };
        });

      setActiveListingsStore(detailedActiveListings);
    } else {
      esl && console.log('activeListingstore_3');
  
      setActiveListingsStore(null);
    }
  }, [activeListings, activeListingDomains, activeListingBids]);
  
  /*
   * Public
   */
  
  const refetchActiveListings = () => {
    setFetchListingsTrigger(prev => prev + 1);
  };
  
  /*
   * Helpers
   */
  
  const fetchStoredPrunedListingIds = (contractAddress: string) => {
    const prunedIdsStorageKey = `${PRUNED_LISTINGS_PREFIX}${contractAddress}`;
    const prunedIdsFromStorage = localStorage.getItem(prunedIdsStorageKey);
    const prunedIdsFromStorageParsed = prunedIdsFromStorage ? JSON.parse(prunedIdsFromStorage).map(BigInt) : [];
  
    return prunedIdsFromStorageParsed;
  };
  
  const updateStoredPrunedIds = (rampAddress: string, prunedListingIdsToStore: bigint[]) => {
    esl && console.log('updateStoredPrunedIds_1: ', rampAddress);
  
    const storageKey = `${PRUNED_LISTINGS_PREFIX}${rampAddress}`;
    const prunedListingIdsForStorage = prunedListingIdsToStore.map(id => id.toString());
    localStorage.setItem(storageKey, JSON.stringify(prunedListingIdsForStorage));
  };

  const fetchAndPruneListings = async (listingCounter: bigint, rampAddress: string) => {
    const existingPrunedIds = fetchStoredPrunedListingIds(rampAddress);
    const listingIdsToFetch = initializeListingIdsToFetch(listingCounter, existingPrunedIds);

    esl && console.log('listingIdsToFetch: ', listingIdsToFetch);
    esl && console.log('existingPrunedIds: ', existingPrunedIds);
  
    const fetchedActiveListings: Listing[] = [];
    const listingIdsToPrune: bigint[] = [];

    const domainIdsToFetch: string[] = [];
    
    for (let i = 0; i < listingIdsToFetch.length; i += BATCH_SIZE) {
      const listingIdBatch = listingIdsToFetch.slice(i, i + BATCH_SIZE);
      esl && console.log('listingIdBatch: ', listingIdBatch);

      const rawListingsData = await fetchingListingsBatch(listingIdBatch);

      esl && console.log('rawListingsData: ', rawListingsData);
      
      const listings = sanitizeRawListings(rawListingsData as any);
      for (let j = 0; j < listings.length; j++) {
        const listing = listings[j];
  
        const listingExpired = !listing.isActive;

        const isInvalidDomainKeyHash = !(listing.dkimKeyHash === '0x0000000000000000000000000000000000000000000000000000000000000000');
  
        esl && console.log('listing: ', listing);

        if (listingExpired || isInvalidDomainKeyHash) {

          listingIdsToPrune.push(listing.listingId);
        } else {
          fetchedActiveListings.push(listing);

          domainIdsToFetch.push(listing.domainId);
        }
      }
    }

    esl && console.log('fetchedActiveListings: ', fetchedActiveListings);
  
    if (currentRampAddressRef.current === rampAddress) {
      const newPrunedListingIds = [...existingPrunedIds, ...listingIdsToPrune];
      updateStoredPrunedIds(rampAddress, newPrunedListingIds);

      setActiveListings(fetchedActiveListings);

      // Set domainIdsToFetch, triggering fetchDomainsBatch
      const uniqueDomainIdsToFetch = [...new Set(domainIdsToFetch)];
      if (uniqueDomainIdsToFetch.length > 0) {
        setActiveListingDomainIds(uniqueDomainIdsToFetch);
        
        setShouldFetchActiveListingDomains(true);
      } else {
        setActiveListingDomainIds(null);

        setShouldFetchActiveListingDomains(false);
      }

      // Set active listing IDs to trigger fetchActiveListingBids
      const fetchedActiveListingIds = fetchedActiveListings.map(listing => BigInt(listing.listingId));
      if (fetchedActiveListingIds.length > 0) {
        setActiveListingIds(fetchedActiveListingIds);
        
        setShouldFetchActiveListingBids(true);
      } else {
        setShouldFetchActiveListingBids(false);

        setActiveListingIds(null);
      }
    }
  };
  
  const initializeListingIdsToFetch = (currentListingCounter: bigint, storedlistingIdsToPrune: bigint[]): bigint[] => {
    if (currentListingCounter) {
      const prunedIdsSet = new Set(storedlistingIdsToPrune.map(id => id.toString()));
      const listingIds = [];
  
      for (let i = 0; i < currentListingCounter; i++) {
        const listingId = BigInt(i).toString();
        if (!prunedIdsSet.has(listingId)) {
          listingIds.push(BigInt(listingId));
        }
      }
  
      return listingIds;
    } else {
      return [];
    }
  };
  
  const fetchingListingsBatch = async (listingIdBatch: bigint[]) => {
    try {
      // function getListings(uint256[] memory _listingIds) external view returns (Listing[] memory listingInfo)
      const data = await readContract({
        address: swapDomainExchangeAddress as `0x${string}`,
        abi: swapDomainExchangeAbi as Abi,
        functionName: 'getListings',
        args: [listingIdBatch],
        account: CALLER_ACCOUNT,
      });
  
      return data;
    } catch (error) {
      console.error('Error fetching listings batch:', error);
      
      return [];
    }
  };
  
  const sanitizeRawListings = (rawListingsData: any[]) => {
    const sanitizedListings: Listing[] = [];
  
    for (let i = rawListingsData.length - 1; i >= 0; i--) {
      const listingWithIdData = rawListingsData[i];
      const listingData = listingWithIdData.listing;
      if (listingData.askPrice === '0') {
        continue;
      }

      const listing: Listing = {
        listingId: listingWithIdData.listingId,
        seller: listingData.seller.toString(),
        price: listingData.askPrice,
        createdAt: listingData.createdAt,
        domainId: listingData.domainId,
        isActive: listingData.isActive,
        minBidPrice: listingData.minBidPrice,
        saleEthRecipientAddress: listingData.saleEthRecipient.toString(),
        dkimKeyHash: listingData.dkimKeyHash.toString(),
        bidIds: listingData.bids,
        encryptionKey: listingData.encryptionKey.substring(2),
      };
  
      sanitizedListings.push(listing);
    }
  
    return sanitizedListings;
  };

  const sanitizeRawBids = (rawBidsData: any[]): Bid[] => {
    const sanitizedBids: Bid[] = [];
  
    for (let i = rawBidsData.length - 1; i >= 0; i--) {
      const bidWithIdData = rawBidsData[i];
      const bidData = bidWithIdData.bid;
      
      const bid: Bid = {
        bidId: bidWithIdData.bidId,
        buyer: bidData.buyer,
        encryptedBuyerId: bidData.encryptedBuyerId,
        buyerIdHash: bidData.buyerIdHash,
        listingId: bidData.listingId,
        price: bidData.price,
        createdAt: bidData.createdAt,
        expiryTimestamp: bidData.expiryTimestamp,
        refundInitiated: bidData.refundInitiated,
        instantAccept: bidWithIdData.buyerInstantAcceptEnabled
      };
  
      sanitizedBids.push(bid);
    }

    return sanitizedBids;
  };

  return (
    <ListingsContext.Provider
      value={{
        activeListingsStore,
        refetchListingCounter,
        refetchActiveListings,
        refetchActiveListingBids,
        shouldFetchActiveListings,
        shouldFetchListingCounter,
      }}
    >
      {children}
    </ListingsContext.Provider>
  );
};

export default Listings;
