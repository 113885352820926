import React, { useCallback, useEffect } from 'react';
import styled, { css } from 'styled-components/macro';
import { useNavigate } from 'react-router-dom';

import { colors } from '@theme/colors';
import { TransactionIconButton } from '@components/common/TransactionIconButton';
import useMediaQuery from '@hooks/useMediaQuery';
import useDeleteListing from '@hooks/transactions/useDeleteListing';
import useSellers from '@hooks/contexts/useSellers';
import { Details } from '@components/Listing/Details';


interface ListingRowProps {
  listingId: string;
  domainName: string;
  numBids: string;
  priceText: string;
  highestBid: string;
  rowIndex: number;
  handleEditListingPressed: () => void;
  isScrolling: boolean;
}

export const ListingsRow: React.FC<ListingRowProps> = ({
  listingId,
  rowIndex,
  domainName,
  numBids,
  priceText,
  highestBid,
  handleEditListingPressed,
  isScrolling
}: ListingRowProps) => {
  const navigate = useNavigate();

  const isMobile = useMediaQuery() === 'mobile';

  const { refetchSellerListingStore } = useSellers();

  /*
   * Hooks
   */

  const onSuccessCallback = useCallback((data: any) => {
    console.log('sellerDeleteListing Succeeded: ', data);

    refetchSellerListingStore?.();
  }, [refetchSellerListingStore]);

  const {
    writeDeleteListingAsync,
    shouldConfigureDeleteListing,
    setListingIdInput: setDeleteListingIdInput,
    setShouldConfigureDeleteListing,
    signDeleteListingTransactionStatus,
    mineDeleteListingTransactionStatus,
    // transactionHash,
  } = useDeleteListing(onSuccessCallback);

  useEffect(() => {
    const submitDeleteListing = async () => {
      if (shouldConfigureDeleteListing && writeDeleteListingAsync) {
        try {
          await writeDeleteListingAsync?.();
        } catch (error) {
          console.log('writeDeleteListingAsync failed: ', error);

          setShouldConfigureDeleteListing(false);

          setDeleteListingIdInput(null);
        }
      };
    };

    submitDeleteListing();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldConfigureDeleteListing, writeDeleteListingAsync]);

  
  /*
   * Handlers
   */

  const handleDeleteListingPressed = () => {
    console.log('handleDeleteListingPressed: ', listingId);
    
    setDeleteListingIdInput(listingId);

    setShouldConfigureDeleteListing(true);
  };
  


  /*
   * Helpers
   */

  const isDeleteListingLoading = (): boolean => {
    const isSigningTransctionMining = signDeleteListingTransactionStatus === 'loading';
    const isMiningTransaction = mineDeleteListingTransactionStatus === 'loading';

    return isSigningTransctionMining || isMiningTransaction;
  };
    
  
  /*
   * Handlers
   */

  const handleDomainClick = () => {
    navigate(`/listing/${listingId}`);
  };

  return (
    <Container scrolling={isScrolling}>
      <ListingDetailsContainer isMobile={isMobile}>
        <DetailsContainer>
          <SummaryLabelValue>
            {rowIndex + 1}
          </SummaryLabelValue>
        </DetailsContainer>

        <DetailsContainer>
          <DomainLabelValue onClick={handleDomainClick}>{domainName}</DomainLabelValue>
        </DetailsContainer>

        <DetailsContainer>
          <SummaryLabelValue>{numBids}</SummaryLabelValue>
        </DetailsContainer>

        <PriceDetailsContainer>
          <PriceLabel>
            {priceText}
          </PriceLabel>
        </PriceDetailsContainer>

        <PriceDetailsContainer>
          <PriceLabel>
            {highestBid}
          </PriceLabel>
        </PriceDetailsContainer>

        <ActionsContainer>
          <TransactionIconButton
            icon="edit"
            onClick={handleEditListingPressed}
            text="Edit Listing"
            loading={false}
            disabled={false}
          />

          <TransactionIconButton
            icon="trash" 
            onClick={handleDeleteListingPressed}
            text="Delete Listing"
            loading={isDeleteListingLoading()}
            disabled={false}
          />
        </ActionsContainer>
      </ListingDetailsContainer>
    </Container>
  );
};

const Container = styled.div<{scrolling: boolean}>`
  display: flex;

  ${({ scrolling }) => scrolling && css`
    padding-right: 0.5rem;
    transition: padding-right 0.3s ease;
  `}

  ${({ scrolling }) => !scrolling && css`
    padding-right: 0rem;
    transition: padding-right 0.3s ease;
  `}
`;

const ListingDetailsContainer = styled.div<{isMobile?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: 0.5fr 1.5fr 1fr 1fr 1fr 1fr;
  padding: 0rem 1.5rem;
  line-height: 24px;
`;

const DetailsContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1rem 0rem;
`;

const PriceDetailsContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 1rem 0rem;
`;

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1.25rem;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 0.75rem 0rem;
`;

const DomainLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.linkBlue};
  cursor: pointer;
  font-weight: 600;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SummaryLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PriceLabel = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;