import React, { useCallback, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { ArrowLeft } from 'react-feather';

import { Input } from '@components/Listing/BidModal/Input';
import { Button } from '@components/common/Button';
import { Overlay } from '@components/modals/Overlay';
import {
  DetailedBid,
  IncreaseBidTransactionStatus,
  IncreaseBidTransactionStatusType,
  LoginStatus
} from '@helpers/types';
import { formatEventDateTime } from '@helpers/dateFormat';
import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { commonStrings } from '@helpers/strings';
import { Z_INDEX } from '@theme/zIndex';
import { toEthString, toBigIntEth, toEthStringWithDecimals } from '@helpers/units';
import useAccount from '@hooks/contexts/useAccount';
import useBuyers from '@hooks/contexts/useBuyers';
import useBalances from '@hooks/contexts/useBalance';
import useIncreaseBidPriceTransaction from '@hooks/transactions/increaseBidPrice';

interface IncreaseBidModalProps {
  bid: DetailedBid
  onBackClick: () => void
}

export const IncreaseBidModal: React.FC<IncreaseBidModalProps> = ({
  bid,
  onBackClick,
}) => {
  /*
   * Contexts
   */

  const { ethBalance } = useBalances();
  const { loginStatus } = useAccount();
  const { refetchBuyerBids } = useBuyers();

  /*
   * State
   */

  const [updateBidStatus, setUpdateBidStatus] = useState<IncreaseBidTransactionStatusType>(IncreaseBidTransactionStatus.DEFAULT);

  /*
   * Hooks
   */

  const onUpdateBidSuccessCallback = useCallback((data: any) => {
    console.log('updateBid Succeeded: ', data);

    refetchBuyerBids?.();
  }, [refetchBuyerBids]);

  const {
    writeIncreaseBidPriceAsync,
    newPriceInput,
    setBidIdInput,
    setNewPriceInput,
    setEthAmountInput,
    setShouldConfigureIncreaseBidPriceWrite,
    signIncreaseBidPriceTransactionStatus,
    mineIncreaseBidPriceTransactionStatus,
    // transactionHash
  } = useIncreaseBidPriceTransaction(onUpdateBidSuccessCallback);

  useEffect(() => {
    if (!bid) {
      return;
    };

    setBidIdInput(bid.bidId);

    const currentBidPrice = toEthStringWithDecimals(bid.price, true, 18, true);
    setNewPriceInput(currentBidPrice);

    setEthAmountInput('0');
  }, [bid, setBidIdInput, setNewPriceInput, setEthAmountInput]);

  useEffect(() => {
    const updateEditBidStatus = async () => {
      const successfulIncreaseBidPriceTransaction = mineIncreaseBidPriceTransactionStatus === 'success';

      if (successfulIncreaseBidPriceTransaction) {
        setUpdateBidStatus(IncreaseBidTransactionStatus.TRANSACTION_SUCCEEDED);
      } else {
        if (newPriceInput) {
          const isNewPriceGreater = toBigIntEth(newPriceInput) > bid.price;

          if (isNewPriceGreater) {
            const signIncreaseBidPriceTransaction = signIncreaseBidPriceTransactionStatus === 'loading';
            const mineIncreaseBidPriceTransaction = mineIncreaseBidPriceTransactionStatus === 'loading';
  
            if (signIncreaseBidPriceTransaction) {
              setUpdateBidStatus(IncreaseBidTransactionStatus.TRANSACTION_SIGNING);
            } else if (mineIncreaseBidPriceTransaction) {
              setUpdateBidStatus(IncreaseBidTransactionStatus.TRANSACTION_MINING);
            } else {
              setUpdateBidStatus(IncreaseBidTransactionStatus.VALID);
            }
          } else {
            setUpdateBidStatus(IncreaseBidTransactionStatus.PRICE_NOT_GREATER);
          }
        } else {
          setUpdateBidStatus(IncreaseBidTransactionStatus.DEFAULT);
        }
      }
    }

  
    updateEditBidStatus();
  }, [
      newPriceInput,
      bid,
      signIncreaseBidPriceTransactionStatus,
      mineIncreaseBidPriceTransactionStatus,
  ]);

  useEffect(() => {
    console.log('updateBidStatus: ', updateBidStatus);

    setShouldConfigureIncreaseBidPriceWrite(updateBidStatus === IncreaseBidTransactionStatus.VALID);
  }, [updateBidStatus, setShouldConfigureIncreaseBidPriceWrite]);

  /*
   * Handlers
   */

  const handlePriceInputChange = (value: string) => {
    const deltaEthAmount = toBigIntEth(value) - bid.price;
    if (value === "") {
      setNewPriceInput('');

      setEthAmountInput('');
    } else if (value === ".") {
      setNewPriceInput('0.');

      setEthAmountInput('');
    } else if (isValidInput(value)) {
      setNewPriceInput(value);
      
      setEthAmountInput(deltaEthAmount.toString());
    }
  };

  const handleOverlayClick = () => {
    onBackClick();
  };

  const ctaOnClick = async () => {
    switch (updateBidStatus) {
      case IncreaseBidTransactionStatus.VALID:
        try {
          await writeIncreaseBidPriceAsync?.();
        } catch (error) {
          console.log('writeUpdateBidAsync failed: ', error);
        }
        break;

      case IncreaseBidTransactionStatus.TRANSACTION_SUCCEEDED:
        onBackClick();
        break;

      default:
        break;
    }
  };

  /*
   * Helpers
   */

  const ethBalanceLabel = useMemo(() => {
    if (ethBalance !== null) {
      return `${toEthString(ethBalance, false, 4)} ETH`
    } else {
      return '';
    }
  }, [ethBalance]);

  function isValidInput(value: string) {
    const isValidInputType = /^-?\d*(\.\d{0,18})?$/.test(value);
    
    return isValidInputType;
  }

  const ctaDisabled = (): boolean => {
    switch (updateBidStatus) {
      case IncreaseBidTransactionStatus.DEFAULT:
      case IncreaseBidTransactionStatus.PRICE_NOT_GREATER:
      case IncreaseBidTransactionStatus.TRANSACTION_SIGNING:
      case IncreaseBidTransactionStatus.TRANSACTION_MINING:
        return true;

      case IncreaseBidTransactionStatus.VALID:
      default:
        return false;
    }
  }

  const ctaLoading = (): boolean => {
    switch (updateBidStatus) {
      case IncreaseBidTransactionStatus.TRANSACTION_SIGNING:
      case IncreaseBidTransactionStatus.TRANSACTION_MINING:
        return loginStatus === LoginStatus.AUTHENTICATED;

      default:
        return false;
    }
  };

  const ctaText = (): string => {
    switch (updateBidStatus) {
      case IncreaseBidTransactionStatus.PRICE_NOT_GREATER:
        return 'New price must be greater than old price';
      
      case IncreaseBidTransactionStatus.TRANSACTION_SIGNING:
        return 'Signing Transaction';

      case IncreaseBidTransactionStatus.TRANSACTION_MINING:
        return 'Mining Transaction';

      case IncreaseBidTransactionStatus.VALID:
        return 'Update Bid';

      case IncreaseBidTransactionStatus.TRANSACTION_SUCCEEDED:
        return 'Go Back';

      case IncreaseBidTransactionStatus.DEFAULT:
      default:
        return 'Input new bid price';
    }
  };

  /*
   * Component
   */

  return (
    <ModalAndOverlayContainer>
      <Overlay onClick={handleOverlayClick}/>

      <ModalContainer>
        <RowBetween>
          <div style={{ flex: 0.25 }}>
            <button
              onClick={handleOverlayClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Increase Bid
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.25 }}/>
        </RowBetween>

        <InstructionsContainer>
          { commonStrings.get('INCREASE_BID_INSTRUCTIONS') }
        </InstructionsContainer>

        <BodyContainer>
          <EventDetailsContainer>
            <DetailsTitle>
              Domain Details
            </DetailsTitle>

            <HorizontalDivider/>

            <DetailsContainer>
              <DetailsValue>Name: {bid.domain.name}</DetailsValue>
              <DetailsValue>Expiry: {formatEventDateTime(bid.domain.expiryTime)}</DetailsValue>
              <DetailsValue>Ask Price: {toEthStringWithDecimals(bid.price, true, 4)} ETH</DetailsValue>
            </DetailsContainer>
          </EventDetailsContainer>

          <Input
            label="Bid Amount"
            name={`bidAmount`}
            value={newPriceInput}
            onChange={(e) => handlePriceInputChange(e.currentTarget.value)}
            type="number"
            inputLabel="ETH"
            accessoryLabel={ethBalanceLabel}
            placeholder="0.0001"
            helperText={commonStrings.get('INCREASE_BID_PRICE_TOOLTIP')}
          />

          <Button
            disabled={ctaDisabled()}
            loading={ctaLoading()}
            onClick={async () => {
              ctaOnClick();
            }}
            fullWidth={true}
          >
            { ctaText() }
          </Button>
        </BodyContainer>
      </ModalContainer>
    </ModalAndOverlayContainer>
  );
};

const ModalAndOverlayContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  align-items: flex-start;
  top: 0;
  left: 0;
  z-index: ${Z_INDEX.overlay};
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: ${colors.black};
`;

const ModalContainer = styled.div`
  max-height: 80vh;
  width: 80vw;
  max-width: 400px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  padding: 1.5rem 1.5rem;
  background-color: ${colors.container};
  color: ${colors.black};
  z-index: ${Z_INDEX.buy_modal};
  gap: 1rem;
  overflow-y: auto;

  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const RowBetween = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1.5rem;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

const InstructionsContainer = styled.div`
  padding: 0rem 0.75rem;
  color: ${colors.darkText};
  font-size: 15px;
`;

const EventDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0rem;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: 16px;
  border: 1px solid ${colors.defaultBorderColor};
  background-color: #FAF9F6;
`;

const HorizontalDivider = styled.div`
  width: 100%;
  border-top: 1px solid ${colors.defaultBorderColor};
  margin: 0.75rem 0 1rem;
`;

const DetailsTitle = styled.div`
  font-size: 14px;
  padding: 0 1rem;
  color: ${colors.darkText};
`;

const DetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  gap: 0.5rem;
`;

const DetailsValue = styled.div`
  font-size: 14px;
  color: #505458;
`;
