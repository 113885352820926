import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro'
import { ArrowLeft } from 'react-feather';
import Link from '@mui/material/Link';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { RowBetween } from '@components/layouts/Row';
import { VerifyInstructions } from '@components/SellerListings/NewListing/VerifyDomains/VerifyInstructions';
import { ProofTable } from '@components/SellerListings/NewListing/VerifyDomains/ProofTable';
import { commonStrings } from '@helpers/strings';
import { parseProof } from '@helpers/parseProof';
import { ExtensionNotaryProofRow, LoginStatus, VerifyTransactionStatus } from '@helpers/types';
import useAccount from '@hooks/contexts/useAccount';
import useExtensionNotarizations from '@hooks/contexts/useExtensionProxyProofs';
import useSellers from '@hooks/contexts/useSellers';
import useVerifyDomainsTransaction from '@hooks/transactions/useVerifyDomains';
import { VERIFY_DOMAINS_DOCS_LINK } from '@helpers/docUrls';

interface VerifyDomainsFormProps {
  handleBackClick: () => void;
};

export const VerifyDomainsForm: React.FC<VerifyDomainsFormProps> = ({
  handleBackClick
}) => {
  /*
   * Context
   */

  const { loginStatus } = useAccount();
  const { sellerDomains, refetchSellerDomains } = useSellers();
  const { archiveNotarizationInSidebar } = useExtensionNotarizations();

  /*
   * State
   */

  const [sellerExistingDomainsRawIds, setSellerExistingDomainsRawIds] = useState<string[]>([]);

  const [verifyDomainStatus, setVerifyDomainStatus] = useState<string>(VerifyTransactionStatus.DEFAULT);
  
  const [verifiedDomainProofs, setVerifiedDomainProofs] = useState<ExtensionNotaryProofRow[]>([]);

  /*
   * Hooks
   */

  const onSuccessCallback = useCallback((data: any) => {
    console.log('VerifyDomains Succeeded: ', data);

    // Refetch all of the seller's domains, no dependencies
    refetchSellerDomains?.();
  }, [refetchSellerDomains]);

  const {
    writeVerifyDomainsAsync,
    domainProofsInput,
    setDomainProofsInput,
    setShouldConfigureVerifyDomainsWrite,
    signVerifyDomainsTransactionStatus,
    mineVerifyDomainsTransactionStatus,
    // transactionHash,
  } = useVerifyDomainsTransaction(onSuccessCallback);

  useEffect(() => {
    setShouldConfigureVerifyDomainsWrite(verifyDomainStatus === VerifyTransactionStatus.VALID);
  }, [verifyDomainStatus, setShouldConfigureVerifyDomainsWrite]);

  useEffect(() => {
    if (sellerDomains) {
      const rawExistingDomainIds = sellerDomains.map(domain => domain.domainId);

      setSellerExistingDomainsRawIds(rawExistingDomainIds); 
    } else {
      setSellerExistingDomainsRawIds([]);
    }
  }, [sellerDomains]);

  useEffect(() => {
    if (verifiedDomainProofs.length > 0) {
      setDomainProofsInput(verifiedDomainProofs.map(proof => parseProof(proof.id, proof.proof)));
    } else {
      setDomainProofsInput([]);
    }
  }, [verifiedDomainProofs, setDomainProofsInput]);

  useEffect(() => {
    const updateVerifyDomainsStatus = async () => {
      const successfulVerificationTransaction = mineVerifyDomainsTransactionStatus === 'success';
      
      if (successfulVerificationTransaction) {
        setVerifyDomainStatus(VerifyTransactionStatus.TRANSACTION_SUCCEEDED);
        // Archive only the proofs submitted in the transaction
        domainProofsInput.forEach(proof => {
          const matchingVerifiedProof = verifiedDomainProofs.find(p => p.id === proof.id);
          if (matchingVerifiedProof) {
            archiveNotarizationInSidebar(matchingVerifiedProof.id);
          }
        });
        setDomainProofsInput([]);
      } else {
        const domainsSelected = domainProofsInput.length > 0;

        if (domainsSelected) {
          const signingVerifyDomainsTransaction = signVerifyDomainsTransactionStatus === 'loading';
          const miningVerifyDomainsTransaction = mineVerifyDomainsTransactionStatus === 'loading';

          if (signingVerifyDomainsTransaction) {
            setVerifyDomainStatus(VerifyTransactionStatus.TRANSACTION_SIGNING);
          } else if (miningVerifyDomainsTransaction) {
            setVerifyDomainStatus(VerifyTransactionStatus.TRANSACTION_MINING);
          } else {
            setVerifyDomainStatus(VerifyTransactionStatus.VALID);
          }
        } else {
          setVerifyDomainStatus(VerifyTransactionStatus.DEFAULT);
        }
      }
    }

    updateVerifyDomainsStatus();
  }, [
      domainProofsInput,
      signVerifyDomainsTransactionStatus,
      mineVerifyDomainsTransactionStatus,
      archiveNotarizationInSidebar,
      verifiedDomainProofs,
      setDomainProofsInput,
    ]
  );

  /*
   * Helpers
   */

  const ctaOnClick = async () => {
    switch (verifyDomainStatus) {
      case VerifyTransactionStatus.VALID:
        try {
          await writeVerifyDomainsAsync?.();

        } catch (error) {
          console.log('writeVerifyDomainsAsync failed: ', error);
        }
        break;
        
      case VerifyTransactionStatus.TRANSACTION_SUCCEEDED:
        handleBackClick();
        break;

      default:
        break;
    }
  }

  /*
   * Component
   */

  return (
    <Container>
      <TitleContainer>
        <RowBetween style={{ padding: '0.25rem 0rem 0.25rem 0rem' }}>
          <div style={{ flex: 0.5 }}>
            <button
              onClick={handleBackClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Verify Domains
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.5 }}/>
        </RowBetween>

        <InstructionContainer>
          {
            <>
              {commonStrings.get('PROOF_FORM_VERIFY_DOMAIN_INSTRUCTIONS')}
              <Link
                href={VERIFY_DOMAINS_DOCS_LINK}
                target='_blank'>
                  Guide ↗
              </Link>
            </>
          }

          {loginStatus === LoginStatus.EOA && (
            <>
              {commonStrings.get('DOMAIN_VERIFICATION_ETH_REQUIRED')}

              <Link
                href={'https://bridge.base.org/deposit'}
                target='_blank'>
                  Base Bridge ↗
              </Link>
            </>
          )}
        </InstructionContainer>

        <VerifyInstructions/>
      </TitleContainer>

      <VerticalDivider/>

      <ProofTable
        sellerRawDomainIds={sellerExistingDomainsRawIds}
        setProofsToSubmit={setVerifiedDomainProofs}
        handleSubmitTransactionPressed={ctaOnClick}
        proofSelectionStatus={verifyDomainStatus}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 660px;
`;

const TitleContainer = styled.div`
  padding: 1.5rem;
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  
  @media (min-width: 600px) {
    max-width: 606px;
    border-radius: 16px;
    border: 1px solid ${colors.defaultBorderColor};
  }
`;

const InstructionContainer = styled.span`
  display: block;
  padding: 1rem 0.5rem;
  color: #333;
  text-align: left;
  line-height: 1.4;
  font-size: 15px;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: #333;
`;

const VerticalDivider = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${colors.defaultBorderColor};
  margin: 0 auto;
`;
