import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components/macro'
import { ArrowLeft } from 'react-feather';
import Confetti from 'react-confetti';
import { useWindowSize } from '@uidotdev/usehooks';
import Link from '@mui/material/Link';
import { useNavigate } from 'react-router-dom';

import { ThemedText } from '@theme/text';
import { colors } from '@theme/colors';
import { RowBetween } from '@components/layouts/Row';
import { ProofGenerationForm } from '@components/ProofGen/ProofForm';
import { CircuitType } from '@zkp2p/circuits-circom-helpers/generate_input';
import { PaymentPlatform } from '@helpers/types';
import { commonStrings } from '@helpers/strings';
import { getEmptyProof } from '@helpers/submitProof';
import { TransferInstructions } from '@components/Listing/FinalizeSale/TransferInstructions';
import { LoginStatus, FinalizeSaleTransactionStatus } from '@helpers/types';
import { RemoteProofGenEmailTypes, SEND_KEY_FILE_NAME } from "@helpers/constants";
import { reformatProofForChain } from "@helpers/submitProof";
import { Z_INDEX } from '@theme/zIndex';
import useAccount from '@hooks/contexts/useAccount';
import useBalances from '@hooks/contexts/useBalance';
import useSellers from '@hooks/contexts/useSellers';
import useFinalizeSale from '@hooks/transactions/useFinalizeSale';


export interface FinalizeSaleBidDetails {
  bidId: string;
  domainName: string;
  decryptedBuyerId: string;
};

interface FinalizeSaleFormProps {
  handleBackClick: () => void;
  bidDetails: FinalizeSaleBidDetails;
};

export const FinalizeSaleForm: React.FC<FinalizeSaleFormProps> = ({
  handleBackClick,
  bidDetails
}) => {
  const size = useWindowSize();
  const navigate = useNavigate();

  /*
   * Context
   */

  const { loginStatus } = useAccount();
  const { refetchSellerListingStore } = useSellers();
  const { refetchEthBalance } = useBalances();

  /*
   * State
   */

  const [finalizeSaleStatus, setFinalizeSaleStatus] = useState<string>(FinalizeSaleTransactionStatus.DEFAULT);
  
  const [transferProof, setTransferProof] = useState<string | null>(null); 
  const [transferPublicSignals, setTransferPublicSignals] = useState<string | null>(null);

  const [showConfetti, setShowConfetti] = useState<boolean>(false);

  // Unused
  const [bodyHashProof, setBodyHashProof] = useState<string>('');
  const [bodyHashPublicSignals, setBodyHashPublicSignals] = useState<string>('');

  /*
   * Hooks
   */

  const onSuccessCallback = useCallback((data: any) => {
    console.log('finalizeSale Succeeded: ', data);

    refetchEthBalance?.();

    refetchSellerListingStore?.();
  }, [refetchSellerListingStore, refetchEthBalance]);

  const {
    writeFinalizeSaleAsync,
    transferProofInput,
    setTransferProofInput,
    setShouldConfigureFinalizeSaleWrite,
    signFinalizeSaleTransactionStatus,
    mineFinalizeSaleTransactionStatus,
    isMineFinalizeSaleTransactionMining,
    isMineFinalizeSaleTransactionSuccessful,
    transactionHash: finalizeSaleTransactionHash,
  } = useFinalizeSale(onSuccessCallback);

  useEffect(() => {
    setShouldConfigureFinalizeSaleWrite(finalizeSaleStatus === FinalizeSaleTransactionStatus.VALID);
  }, [finalizeSaleStatus, setShouldConfigureFinalizeSaleWrite]);

  useEffect(() => {
    if (transferProof && transferPublicSignals) {
      const parsedTransferProof = reformatProofForChain(transferProof, transferPublicSignals);

      setTransferProofInput(parsedTransferProof);
    } else {
      setTransferProofInput(getEmptyProof());
    }
  }, [transferProof, transferPublicSignals, setTransferProofInput]);


  useEffect(() => {
    const updateVerifyDomainsStatus = async () => {
      const successfulVerificationTransaction = mineFinalizeSaleTransactionStatus === 'success';
      if (successfulVerificationTransaction) {
        setFinalizeSaleStatus(FinalizeSaleTransactionStatus.TRANSACTION_SUCCEEDED);
      } else {
        const transferProofSelected = transferProof !== null;

        if (transferProofSelected) {
          const signingVerifyDomainsTransaction = signFinalizeSaleTransactionStatus === 'loading';
          const miningVerifyDomainsTransaction = mineFinalizeSaleTransactionStatus === 'loading';

          if (signingVerifyDomainsTransaction) {
            setFinalizeSaleStatus(FinalizeSaleTransactionStatus.TRANSACTION_SIGNING);
          } else if (miningVerifyDomainsTransaction) {
            setFinalizeSaleStatus(FinalizeSaleTransactionStatus.TRANSACTION_MINING);
          } else {
            setFinalizeSaleStatus(FinalizeSaleTransactionStatus.VALID);
          }
        } else {
          setFinalizeSaleStatus(FinalizeSaleTransactionStatus.DEFAULT);
        }
      }
    }

    updateVerifyDomainsStatus();
  }, [
      transferProof,
      transferProofInput,
      signFinalizeSaleTransactionStatus,
      mineFinalizeSaleTransactionStatus,
    ]
  );

  useEffect(() => {
    if (mineFinalizeSaleTransactionStatus === 'success') {
      setShowConfetti(true);
      
      setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
    }
  }, [mineFinalizeSaleTransactionStatus])

  /*
   * Helpers
   */

  const handleClickAfterFinalizeSale = async () => {
    navigate('/my-listings');
  }

  const ctaOnClick = async () => {
    switch (finalizeSaleStatus) {
      case FinalizeSaleTransactionStatus.VALID:
        try {
          await writeFinalizeSaleAsync?.();
        } catch (error) {
          console.log('writeFinalizeSaleAsync failed: ', error);
        }
        break;

      case FinalizeSaleTransactionStatus.TRANSACTION_SUCCEEDED:
        handleBackClick();
        break;

      default:
        break;
    }
  }

  /*
   * Component
   */

  return (
    <Container>
      {showConfetti ? (
        <ConfettiContainer>
          <Confetti
            recycle={false}
            numberOfPieces={500}
            width={size.width ?? undefined}
            height={document.documentElement.scrollHeight}
          />
        </ConfettiContainer>
      ) : null}

      <TitleContainer>
        <RowBetween style={{ padding: '0.25rem 0rem 0.25rem 0rem' }}>
          <div style={{ flex: 0.5 }}>
            <button
              onClick={handleBackClick}
              style={{ background: 'none', border: 'none', cursor: 'pointer' }}
            >
              <StyledArrowLeft/>
            </button>
          </div>

          <ThemedText.HeadlineSmall style={{ flex: '1', margin: 'auto', textAlign: 'center' }}>
            Finalize Sale
          </ThemedText.HeadlineSmall>

          <div style={{ flex: 0.5 }}/>
        </RowBetween>

        <InstructionContainer>
          {commonStrings.get('PROOF_FORM_FINALIZE_SALE_INSTRUCTIONS')}

          {loginStatus === LoginStatus.EOA && (
            <>
              {commonStrings.get('FINALIZE_SALE_ETH_REQUIRED')}

              <Link
                href={'https://bridge.base.org/deposit'}
                target='_blank'>
                  Base Bridge ↗
              </Link>
            </>
          )}
        </InstructionContainer>

        <TransferInstructions 
          bidDetails={bidDetails}
        />
      </TitleContainer>

      <VerticalDivider/>

      <ProofGenerationForm
        paymentPlatformType={PaymentPlatform.NAMECHEAP}
        circuitType={CircuitType.EMAIL_NAMECHEAP_PUSH_DOMAIN}
        circuitRemoteFilePath={SEND_KEY_FILE_NAME}
        circuitInputs={bidDetails?.bidId.toString() ?? ''}
        remoteProofGenEmailType={RemoteProofGenEmailTypes.PUSH}
        proof={transferProof ?? ''}
        publicSignals={transferPublicSignals ?? ''}
        setProof={setTransferProof}
        setPublicSignals={setTransferPublicSignals}
        bodyHashProof={bodyHashProof}
        bodyHashPublicSignals={bodyHashPublicSignals}
        setBodyHashProof={setBodyHashProof}
        setBodyHashPublicSignals={setBodyHashPublicSignals}
        submitTransactionStatus={signFinalizeSaleTransactionStatus}
        isSubmitMining={isMineFinalizeSaleTransactionMining}
        isSubmitSuccessful={isMineFinalizeSaleTransactionSuccessful}
        handleSubmitVerificationClick={ctaOnClick}
        onVerifyEmailCompletion={handleClickAfterFinalizeSale}
        transactionAddress={finalizeSaleTransactionHash}
      />
    </Container>
  );
};

const Container = styled.div`
  margin: auto;
  max-width: 660px;
`;

const ConfettiContainer = styled.div`
  z-index: ${Z_INDEX.confetti};
`;

const TitleContainer = styled.div`
  padding: 1.5rem;
  background-color: ${colors.container};
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  
  @media (min-width: 600px) {
    max-width: 606px;
    border-radius: 16px;
  }
`;

const InstructionContainer = styled.span`
  display: block;
  padding: 0 0.5rem;
  color: #333;
  text-align: left;
  line-height: 1.4;
  font-size: 15px;
`;

const StyledArrowLeft = styled(ArrowLeft)`
  color: #333;
`;

const VerticalDivider = styled.div`
  height: 24px;
  width: 1px;
  background-color: ${colors.defaultBorderColor};
  margin: 0 auto;
`;
