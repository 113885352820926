import React from 'react';
import styled, { css } from 'styled-components';
import { useNavigate } from 'react-router-dom';

import useMediaQuery from '@hooks/useMediaQuery';
import { colors } from '@theme/colors';
import { toEthStringWithDecimals } from '@helpers/units';
import { AccessoryButton } from '@components/common/AccessoryButton';
interface ListingRowProps {
  listingId: string;
  domainName: string;
  numBids: number;
  priceText: string;
  highestBid: bigint;
  rowIndex: number;
  isScrolling: boolean;
}

export const ListingRow: React.FC<ListingRowProps> = ({
  listingId,
  rowIndex,
  domainName,
  numBids,
  priceText,
  highestBid,
  isScrolling
}: ListingRowProps) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery() === 'mobile';


  /*
   * Handlers
   */

  const handleDomainClick = () => {
    navigate(`/listing/${listingId}`);
  };

  return (
    <Container scrolling={isScrolling}>
      <ListingDetailsContainer isMobile={isMobile}>
        <SummaryLabelValue>
          {rowIndex + 1}
        </SummaryLabelValue>

        <DetailsContainer>
          <DomainLabelValue onClick={handleDomainClick}><strong>{domainName}</strong></DomainLabelValue>
        </DetailsContainer>

        <DetailsContainer>
          <SummaryLabelValue>{numBids}</SummaryLabelValue>
        </DetailsContainer>

        <PriceDetailsContainer>
          <PriceLabel>
            {priceText}
          </PriceLabel>
        </PriceDetailsContainer>

        <PriceDetailsContainer>
          <PriceLabel>
            {`${toEthStringWithDecimals(highestBid, true, 4)} ETH`}
          </PriceLabel>
        </PriceDetailsContainer>

        {/* <ButtonWrapper> */}
          <AccessoryButton
            title={'Bid Now'}
            onClick={() => navigate(`/listing/${listingId}`)}
            padding={'4px 10px'}
            height={35}
            width={75}
            fullWidth={false}
          />
        {/* </ButtonWrapper> */}
      </ListingDetailsContainer>
    </Container>
  );
};

const Container = styled.div<{scrolling: boolean}>`
  display: flex;

  ${({ scrolling }) => scrolling && css`
    padding-right: 0.5rem;
    transition: padding-right 0.3s ease;
  `}

  ${({ scrolling }) => !scrolling && css`
    padding-right: 0rem;
    transition: padding-right 0.3s ease;
  `}
`;

const ListingDetailsContainer = styled.div<{isMobile?: boolean}>`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 4fr 2fr 2fr 2fr 2fr;
  align-items: center;
  padding: 1rem 1.5rem;
  line-height: 24px;
`;

const DetailsContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PriceDetailsContainer = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const DomainLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.linkBlue};
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SummaryLabelValue = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PriceLabel = styled.span`
  font-size: 15px;
  color: ${colors.darkText};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;